<template>
<div>
    <!-- PageHeader component with icon, title, and items as props -->
    <PageHeader :icon="'mdi mdi-account-multiple h3'" :title="title" :items="items" />
    <!-- UpdateUser component -->
    <UpdateUser />
</div>
</template>
<script>
import PageHeader from "../../../components/pageheader.vue";
import UpdateUser from "./components/updateuser.vue";
import {
    eventBus
} from "../../../main";
export default {
    components: {
        PageHeader,
        UpdateUser,
    },
    data() {
        return {
            title: "Edit User",
            items: [{
                    text: "Dashboard",
                    href: "/"
                },
                {
                    text: "Users",
                    href: "/users"
                },
                {
                    text: "Edit User",
                    active: true
                }
            ],
        };
    },
    mounted() {
        eventBus.$emit('update-sidebar', "menuitems.users.text");
    },
    created(){
        if (!this.$store.state.Auth.gitToken) {
            this.$router.push({
                name: "Projects"
            });
        }
    }
};
</script>
