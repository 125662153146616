<template>
  <div>
    <!-- User Addition Form -->
    <b-container>
      <b-card>
        <form
          class="form-horizontal"
          @submit.prevent="addUser"
          ref="formAddUser"
        >
          <!-- Collaborators Selection -->
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="addf_username"
                  >Username <span class="text-danger">*</span></label
                >
                <!-- Input field for Username -->
                <input
                  v-validate="'required'"
                  v-model="formData.username"
                  name="addf_username"
                  type="text"
                  class="form-control"
                  id="addf_username"
                  placeholder="Enter username"
                  :class="{
                    'is-invalid': submitted && $v.addf_username.$error,
                  }"
                />
                <!-- Validation message for Username -->
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('addf_username')"
                >
                  The username field is required
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <!-- Username Input -->
              <div class="form-group">
                <label for="addf_collaborators"
                  >Collaborators <span class="text-danger">*</span></label
                >
                <!-- Select input for Collaborators -->
                <b-form-select
                  :options="optionsCollaboratorlist"
                  v-validate="'required'"
                  v-model="selectedCollaborators"
                  name="addf_collaborators"
                  type="text"
                  class="form-control"
                  id="addf_collaborators"
                  placeholder="Select collaborators"
                  :class="{
                    'is-invalid': submitted && $v.addf_collaborators.$error,
                  }"
                  @change="onCollaboratorSel"
                ></b-form-select>
                <!-- Validation message for Collaborators -->
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('addf_collaborators')"
                >
                  The Collaborators field is required
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <!-- Projects Selection -->
              <div class="form-group">
                <label for="addf_projects"
                  >Projects <span class="text-danger">*</span></label
                >
                <!-- Multiselect input for Projects -->
                <multiselect
                  v-validate="'required'"
                  name="addf_projects"
                  id="addf_projects"
                  :class="{
                    'is-invalid': submitted && $v.addf_projects.$error,
                  }"
                  v-model="seletedProject"
                  tag-placeholder="Add this as a new tag"
                  placeholder="Please select a project"
                  label="text"
                  track-by="value"
                  :options="optionsProjectlist"
                  :multiple="true"
                ></multiselect>
                <!-- Validation message for Projects -->
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('addf_projects')"
                >
                  The projects field is required
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <!-- Email Input -->
              <div class="form-group">
                <label for="addf_email"
                  >Email Address <span class="text-danger">*</span></label
                >
                <!-- Input field for Email Address -->
                <input
                  v-validate="'required'"
                  v-model="formData.email"
                  name="addf_email"
                  type="text"
                  class="form-control"
                  id="addf_email"
                  placeholder="Enter email"
                  :class="{
                    'is-invalid': submitted && $v.addf_email.$error,
                  }"
                />
                <!-- Validation message for Email Address -->
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('addf_email')"
                >
                  The email field is required
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <!-- Password Input -->
              <div class="form-group">
                <label for="addf_password"
                  >Password <span class="text-danger">*</span></label
                >
                <div class="input-group">
                  <!-- Input field for Password with toggle button -->
                  <input
                    v-validate="'required|verify_password'"
                    v-model="password"
                    name="addf_password"
                    :type="showPasswordCfrm ? 'text' : 'password'"
                    class="form-control"
                    id="addf_password"
                    placeholder="Enter password"
                    :class="{
                      'is-invalid': submitted && $v.addf_password.$error,
                    }"
                    ref="addf_password"
                  />
                  <!-- Password visibility toggle button -->
                  <div class="input-group-append">
                    <div
                      class="btn btn-outline-light"
                      type="button"
                      @click="toggleCfrmPasswordVisibility()"
                    >
                      <i :class="eyeIconClassCfrm"></i>
                    </div>
                  </div>
                </div>
                <!-- Validation messages for Password -->
                <p
                  class="text-danger text-sm"
                  v-show="
                    errors.has('addf_password') &&
                    _.find(errors.items, { field: 'addf_password' }).rule ==
                      'required'
                  "
                >
                  <span>The password field is required</span>
                </p>
                <p
                  class="text-danger text-sm"
                  v-show="
                    errors.has('addf_password') &&
                    _.find(errors.items, { field: 'addf_password' }).rule ==
                      'verify_password'
                  "
                >
                  {{ errors.first("addf_password") }}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <!-- Github Username Input -->
              <div class="form-group">
                <label for="addf_githubUsername"
                  >Github Username <span class="text-danger">*</span></label
                >
                <!-- Input field for Github Username (disabled) -->
                <input
                  v-validate="'required'"
                  v-model="formData.githubUsername"
                  name="addf_githubUsername"
                  type="text"
                  class="form-control disable-cust-cls"
                  id="addf_githubUsername"
                  placeholder="Enter Github Username"
                  :class="{
                    'is-invalid': submitted && $v.addf_githubUsername.$error,
                  }"
                  disabled
                />
                <!-- Validation message for Github Username -->
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('addf_githubUsername')"
                >
                  The GitHub username field is required
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <!-- Github Email Address Input -->
              <div class="form-group">
                <label for="addf_githubEmail"
                  >Github Email Address
                  <span class="text-danger">*</span></label
                >
                <!-- Input field for Github Email Address -->
                <input
                  v-validate="'required'"
                  v-model="formData.githubEmail"
                  name="addf_githubEmail"
                  type="text"
                  class="form-control"
                  id="addf_githubEmail"
                  placeholder="Enter GitHub email address"
                  :class="{
                    'is-invalid': submitted && $v.addf_githubEmail.$error,
                  }"
                />
                <!-- Validation message for Github Email Address -->
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('addf_githubEmail')"
                >
                  The GitHub email field is required
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <!-- Admin Selection -->
              <div class="form-group">
                <label for="addf_admin"
                  >Admin <span class="text-danger">*</span></label
                >
                <!-- Select input for Admin -->
                <b-form-select
                  :options="optionsAdmins"
                  v-validate="'required'"
                  v-model="selectedAdmin"
                  name="addf_admin"
                  value-field="value"
                  text-field="text"
                  class="form-control"
                  id="addf_admin"
                  placeholder="Select admin"
                  :class="{
                    'is-invalid': submitted && $v.addf_admin.$error,
                  }"
                ></b-form-select>
                <!-- Validation message for Admin -->
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('addf_admin')"
                >
                  The projects field is required
                </p>
              </div>
            </div>
          </div>
          <!-- User Role Selection -->
          <div class="form-group">
            <label for="addf_userrole"
              >User Role <span class="text-danger">*</span></label
            >
            <!-- Checkbox group for User Roles -->
            <b-form-checkbox-group
              :options="optionsRoles"
              v-validate="'required'"
              v-model="formData.userRole"
              name="add_userrole"
              type="text"
              value="true"
              id="addf_userrole"
              :class="{
                'is-invalid': submitted && $v.addf_userrole.$error,
              }"
              :multiple="true"
            ></b-form-checkbox-group>
            <!-- Validation message for User Roles -->
            <p class="text-danger text-sm" v-show="errors.has('addf_userrole')">
              The user roles field is required
            </p>
          </div>

          <hr />
          <!-- Save and Cancel Buttons -->
          <div class="text-right">
            <button
              class="btn btn-sm btn-primary waves-effect waves-light"
              type="submit"
            >
              Save
            </button>
            &nbsp;&nbsp;
            <button class="btn btn-default btn-sm" v-on:click="closeAddUser()">
              Cancel
            </button>
          </div>
        </form>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import VeeValidate from "vee-validate";
import Multiselect from "vue-multiselect";
import CryptoJS from "crypto-js";
import { secretKey } from "../../../../api/global.env";
import _ from "lodash";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      projectCollaboratorsList: [
        {
          id: "1",
          name: "Select Collabrator",
        },
      ],
      projectList: [
        {
          id: "1",
          name: "Select Project",
        },
      ],
      password: "",
      formData: {
        userRole: [],
        selectedCollaborators: null,
        seletedProject: null,
        email: "",
        seletedRole: [],
        username: "",
        githubUsername: "",
        githubEmail: "",
        adminId: "",
        id: "",
        password: "",
      },
      selectedCollaborators: null,
      seletedProject: null,
      selectedAdmin: null,
      error: null,
      selectedMigrationOption: null,
      submitted: false,
      optionsCollaboratorlist: [
        {
          value: null,
          text: "Please select an collaborators",
          disabled: true,
        },
      ],
      optionsProjectlist: [],
      optionsRoles: [],
      appAccess: "",
      optionsAdmins: [
        {
          value: null,
          text: "Please select an admin",
          disabled: true,
        },
      ],
      allCollaborators: "",
      activeUserData: "",
      inactiveUserData: "",
      showPasswordCfrm: false,
      id: CryptoJS.AES.decrypt(this.$route.params.id, secretKey).toString(
        CryptoJS.enc.Utf8
      ),
    };
  },
  mounted() {
    this.getUserProfile();
    this.getOrgDetails();
    this.getOrgAdmins();
    this.getActiveUserData();
  },
  computed: {
    _() {
      return _;
    },
    eyeIconClassNewpass() {
      return this.showPasswordNew ? "fas fa-eye-slash" : "fas fa-eye";
    },
    eyeIconClassCfrm() {
      return this.showPasswordCfrm ? "fas fa-eye-slash" : "fas fa-eye";
    },
  },

  created() {
    VeeValidate.Validator.extend("verify_password", {
      getMessage: () =>
        `The password must contain at least: minimum 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)`,
      validate: (value) => {
        var strongRegex = new RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})"
        );
        return strongRegex.test(value);
      },
    });
    if (!this.$store.state.Auth.gitToken) {
      this.$router.push({
        name: "Projects",
      });
    }
  },
  methods: {
    // This method is use to generate error or success message
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    toggleCfrmPasswordVisibility() {
      this.showPasswordCfrm = !this.showPasswordCfrm;
    },
    getUserProfile() {
      this.$store.getters.client
        .get(`orguser/details?userId=${this.id}`)
        .then((response) => {
          this.formData = response.data;
        })
        .then(() => {
          let filterCollaborators = this._.filter(this.allCollaborators, {
            gitUsername: this.formData.githubUsername,
          });
          if (filterCollaborators.length > 0) {
            const selectedCollaborator = filterCollaborators[0];
            this.selectedCollaborators = selectedCollaborator.gitUserId;
            this.getProjectBySeletedCollaborators(
              selectedCollaborator.gitUserId
            );
          }
          if (!this.formData.userRole.includes("DocManager")) {
            this.formData.userRole.push("DocManager");
          }
        })
        .catch((error) => {
          console.error("Error fetching user profile:", error);
        });
    },
    getProjectBySeletedCollaborators(uid) {
      this.seletedProject = null;
      this.optionsProjectlist = [];
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`project/bygituserid?githubUserId=${uid}`)
        .then((response) => {
          response.data.forEach((element) => {
            this.optionsProjectlist.push({
              value: element.id.toString(),
              text: element.name,
            });
          });
          loader.hide();
        })
        .catch((err) => {
         
          this.messageToast("Error", "danger", err.response.data.message);
          loader.hide();
        })
        .then(() => {
          let projectArr = [];
          this.formData.projects.forEach((element) => {
            let filterProjects = this._.filter(this.optionsProjectlist, {
              value: element,
            });
            projectArr.push(filterProjects[0]);
          });

          this.seletedProject = projectArr;
          loader.hide();
        })
        .catch((err) => {
         
          this.messageToast("Error", "danger", err.response.data.message);
          loader.hide();
        });
    },
    async addUser() {
      this.$validator
        .validateAll()
        .then(async (result) => {
          if (result) {
            let userIP = await fetch("https://api.ipify.org?format=json")
              .then((x) => x.json())
              .then(async ({ ip }) => {
                return ip;
              });
            let projectArr = [];
            this.seletedProject.forEach((element) => {
              projectArr.push(element.value);
            });
            let data = {
              projects: projectArr,
              userRole: this.formData.userRole,
              orgId: this.$store.state.Auth.orgId,
              email: this.formData.email,
              username: this.formData.username,
              password: this.password,
              githubCollaboratorId: this.selectedCollaborators,
              githubUsername: this.formData.githubUsername,
              githubEmail: this.formData.githubEmail,
              adminId: this.selectedAdmin,
              lastLoginId: userIP,
              isActive: true,
              isDeleted: false,
              isSuperUser: false,
            };

            let loader = this.$loading.show({
              loader: "dots",
            });
            this.$store.getters.client
              .put(`orguser/profileupdate?userId=${this.id}`, data)
              .then(() => {
                this.messageToast(
                  "Success",
                  "success",
                  `User profile update successfully`
                );
                loader.hide();
                this.$store.commit("setActiveUserCount", []);
                this.$router.push({
                  path: `/users`,
                });
              })
              .catch((error) => {
                this.messageToast(
                  "Error",
                  "danger",
                  error.response.data.message
                );
                loader.hide();
              });
          }
        })
        .catch((err) => console.log(err));
    },
    async getAllCollaboratorsData() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(
          `orgadmin/github/gitcollaborators?orgId=${this.$store.state.Auth.orgId}`
        )
        .then(async (response) => {
          this.allCollaborators = response.data;
          response.data.forEach((element) => {
            let filterCollaborators = this._.filter(this.activeUserData, {
              githubUsername: element.gitUsername,
            });
            let filterCollaborators2 = this._.filter(this.inactiveUserData, {
              githubUsername: element.gitUsername,
            });
            if (
              filterCollaborators.length === 0 &&
              filterCollaborators2.length === 0
            ) {
              this.optionsCollaboratorlist.push({
                value: element.gitUserId,
                text: element.gitUsername,
              });
            } else {
              this.optionsCollaboratorlist.push({
                value: element.gitUserId,
                text: element.gitUsername,
                disabled: true,
              });
            }
          });
          this.getUserProfile();
          loader.hide();
        })
        .catch(() => {
          loader.hide();
       
          loader.hide();
        });
    },
    onCollaboratorSel(uid) {
      this.seletedProject = null;
      let filterCollaborators = this._.filter(this.allCollaborators, {
        gitUserId: this.selectedCollaborators,
      });
      this.formData.githubUsername = filterCollaborators[0].gitUsername;
      this.optionsProjectlist = [];
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`project/bygituserid?githubUserId=${uid}`)
        .then((response) => {
          response.data.forEach((element) => {
            this.optionsProjectlist.push({
              value: element.id,
              text: element.name,
            });
          });
          loader.hide();
        })
        .catch(() => {
          
          loader.hide();
        });
    },
    closeAddUser() {
      this.$router.push({
        path: `/users`,
      });
    },
    // This method is use to Get organization details
    getOrgDetails() {
      // Try requrest to get organization details
      this.$store.getters.client
        .get(
          `/serveradmin/organization/byorgid?orgId=${this.$store.state.Auth.orgId}`
        )
        .then((res) => {
          this.appAccess = res.data[0];

          if (res.data[0].docManager)
            this.optionsRoles.push({
              text: "DocManager",
              value: "DocManager",
              disabled: true,
            });
          if (res.data[0].publisher)
            this.optionsRoles.push({
              text: "DocPublisher",
              value: "DocPublisher",
            });
          if (res.data[0].editor)
            this.optionsRoles.push({
              text: "DocEditor",
              value: "DocEditor",
            });
          if (res.data[0].docMigration)
            this.optionsRoles.push({
              text: "DocMigration",
              value: "DocMigration",
            });
        })
        .catch(() => {});
    },
    // This method is use to Get organization details
    getOrgAdmins() {
      // Try requrest to get organization details
      this.$store.getters.client
        .get(`orgadmin/byorgid?orgId=${this.$store.state.Auth.orgId}`)
        .then((res) => {
          res.data.forEach((element) => {
            this.optionsAdmins.push({
              value: element.id,
              text: element.name,
            });
          });
        })
        .catch(() => {});
    },
    addUseFormReset() {
      // Resetting Values
      (this.selectedCollaborators = null),
        (this.selectedCollaborators = null),
        (this.seletedRole = []),
        (this.selectedAdmin = null),
        (this.formData.username = ""),
        (this.formData.email = ""),
        (this.password = ""),
        (this.formData.githubEmail = "");
      this.$refs.formAddUser.reset();
    },
    async getActiveUserData() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`orguser/active/profile?orgId=${this.$store.state.Auth.orgId}`)
        .then((response) => {
          this.activeUserData = response.data;
          loader.hide();
          this.getInactiveUserData();
        })
        .then(() => {
          loader.hide();
        })
        .catch(() => {
          loader.hide();
        });
    },
    async getInactiveUserData() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`orguser/inactive/profile?orgId=${this.$store.state.Auth.orgId}`)
        .then((response) => {
          this.inactiveUserData = response.data;
          loader.hide();
          this.getAllCollaboratorsData();
        })
        .catch(() => {
          loader.hide();
        });
    },
  },
};
</script>
<style scoped>
.btn-default {
  border: 2px solid #dcdee2;
}
option:disabled {

  color: #b6b6be !important;
}
.disable-cust-cls {
  background: #e7e7e8 !important;
  color: #838588 !important;
}
</style>
