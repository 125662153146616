<template>
  <div>
    <!-- Container for the component -->
    <div class="row">
      <div class="col-12">
        <div>
          <div class="card-body">
            <!-- Row for search and pagination settings -->
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <!-- "Show entries" dropdown -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-flex align-items-center"
                      ><span class="text-nowrap">Show entries</span>
                      <div class="col-md-3">
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-2 d-flex justify-content-end">
                <div id="tickets-table_filter" class="dataTables_filter">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search Username..."
                    class="form-control form-control-sm"
                  ></b-form-input>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <!-- Bootstrap-Vue table component -->
              <b-table
                :items="inactiveUserData"
                :fields="colfields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :filter-function="filterTable"
                ref="inactiveUserTable"
                show-empty
              >
                <!-- Template for rendering the "status" cell -->
                <template #cell(status)="row">
                  <div
                    class="badge font-size-12"
                    :class="{
                      'badge-soft-danger': `${row.item.isActive}` === 'false',
                      'badge-soft-success': `${row.item.isActive}` === 'true',
                    }"
                  >
                    {{ row.item.isActive === true ? "Active" : "Deactive" }}
                  </div>
                </template>
                <!-- Template for rendering the "action" cell, which contains an edit button -->
                <template #cell(action)="row">
                  <div class="text-primary">
                    <div class="view-details-btn" @click="userAdd(row)">
                      <span class="mdi mdi-pencil px-1"></span>Activate
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
            <!-- Pagination and row count information -->
            <div class="row">
              <div
                class="dataTables_paginate paging_simple_numbers col justify-content-center row-count-cust-cls"
              ></div>
              <div class="row-pagination-cust-cls">
                <ul class="pagination-rounded mb-0">
                  <!-- Pagination controls for navigating between pages -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CryptoJS from "crypto-js";
import { secretKey } from "../../../../api/global.env";
export default {
  data() {
    return {
      inactiveUserData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: true,
      colfields: [
        {
          key: "username",
          label: "Username",
          sortable: false,
        },
        {
          key: "action",
          label: "Action",
          sortable: false,
        },
      ],
      error: null,
      submitted: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.inactiveUserData.length;
    },
  },
  created() {
    if (this.$store.state.Auth.inActiveUserData.length) {
      this.inactiveUserData = JSON.parse(
        JSON.stringify(this.$store.state.Auth.inActiveUserData)
      );
      this.totalRows = this.inactiveUserData.length;
    } else {
      this.getInactiveUserData();
    }
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    // This method is use to generate error or success message
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    getInactiveUserData() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`orguser/inactive/profile?orgId=${this.$store.state.Auth.orgId}`)
        .then((response) => {
          if (response.data) {
            // Data is available, update the component's property
            this.inactiveUserData = response.data;
            this.totalRows = this.inactiveUserData.length;

            loader.hide();
          } else {
            // Handle the case where response.data is empty or undefined
            this.messageToast("Error", "danger", "No inactive user data found");
            loader.hide();
          }
        })
        .catch((err) => {
          // Handle the error and display a message toast
          const errorMessage = err.response
            ? err.response.data.message
            : "An error occurred";
          this.messageToast(
            "Error",
            "danger",
            `Error on fetching inactive user data: ${errorMessage}`
          );
          loader.hide();
        });
    },
    userAdd(data) {
      const encryptedData = CryptoJS.AES.encrypt(
        data.item.id,
        secretKey
      ).toString();
      const encodeddata = encodeURIComponent(encryptedData);
      this.$router.push({
        path: `/useradd/${encodeddata}`,
      });
    },
    filterTable(row, filter) {
      const filterUpperCase = filter.toUpperCase();
      const hasNameMatch = row.username.toUpperCase().includes(filterUpperCase);
      return hasNameMatch;
    },
  },
};
</script>
<style>
.editbtn-cust-cls {
  float: right;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .row-count-cust-cls {
    margin-bottom: 10px;
  }
  .row-pagination-cust-cls {
    margin-top: 20px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .row-count-cust-cls ul {
    margin-bottom: 10px;
    text-align: left !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
