var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('form',{ref:"formUpdateUser",staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();return _vm.updateUser.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_vm._m(0),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.formData.username),expression:"formData.username"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.updatef_username.$error,
                },attrs:{"name":"updatef_username","type":"text","id":"updatef_username","placeholder":"Enter username"},domProps:{"value":(_vm.formData.username)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "username", $event.target.value)}}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('updatef_username')),expression:"errors.has('updatef_username')"}],staticClass:"text-danger text-sm"},[_vm._v(" The username field is required ")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_vm._m(1),_c('b-form-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.updatef_collaborators.$error,
                },attrs:{"options":_vm.optionsCollaboratorlist,"name":"updatef_collaborators","type":"text","id":"updatef_collaborators","placeholder":"Select collaborators","disabled":""},model:{value:(_vm.selectedCollaborators),callback:function ($$v) {_vm.selectedCollaborators=$$v},expression:"selectedCollaborators"}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('updatef_collaborators')),expression:"errors.has('updatef_collaborators')"}],staticClass:"text-danger text-sm"},[_vm._v(" The Collaborators field is required ")])],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_vm._m(2),_c('multiselect',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
                  'is-invalid': _vm.submitted && _vm.$v.addf_projects.$error,
                },attrs:{"name":"updatef_projects","id":"updatef_projects","tag-placeholder":"Add this as a new tag","placeholder":"Please select a project","label":"text","track-by":"value","options":_vm.optionsProjectlist,"multiple":true},model:{value:(_vm.seletedProject),callback:function ($$v) {_vm.seletedProject=$$v},expression:"seletedProject"}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('updatef_projects')),expression:"errors.has('updatef_projects')"}],staticClass:"text-danger text-sm"},[_vm._v(" The projects field is required ")])],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_vm._m(3),_c('b-form-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.updatef_admin.$error,
                },attrs:{"options":_vm.optionsAdmins,"name":"updatef_admin","type":"text","id":"updatef_admin","placeholder":"Select admin"},model:{value:(_vm.selectedAdmin),callback:function ($$v) {_vm.selectedAdmin=$$v},expression:"selectedAdmin"}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('updatef_admin')),expression:"errors.has('updatef_admin')"}],staticClass:"text-danger text-sm"},[_vm._v(" The admin field is required ")])],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_vm._m(4),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.formData.email),expression:"formData.email"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.updatef_email.$error,
                },attrs:{"name":"updatef_email","type":"text","id":"updatef_email","placeholder":"Enter email"},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "email", $event.target.value)}}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('updatef_email')),expression:"errors.has('updatef_email')"}],staticClass:"text-danger text-sm"},[_vm._v(" The email field is required ")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_vm._m(5),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.formData.githubUsername),expression:"formData.githubUsername"}],staticClass:"form-control disable-cust-cls",class:{
                  'is-invalid': _vm.submitted && _vm.$v.updatef_githubUsername.$error,
                },attrs:{"name":"updatef_githubUsername","type":"text","id":"updatef_githubUsername","placeholder":"Enter GitHub Username","disabled":""},domProps:{"value":(_vm.formData.githubUsername)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "githubUsername", $event.target.value)}}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('updatef_githubUsername')),expression:"errors.has('updatef_githubUsername')"}],staticClass:"text-danger text-sm"},[_vm._v(" The GitHub username field is required ")])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_vm._m(6),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.formData.githubEmail),expression:"formData.githubEmail"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.updatef_githubEmail.$error,
                },attrs:{"name":"updatef_githubEmail","type":"text","id":"updatef_githubEmail","placeholder":"Enter GitHub email"},domProps:{"value":(_vm.formData.githubEmail)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "githubEmail", $event.target.value)}}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('updatef_githubEmail')),expression:"errors.has('updatef_githubEmail')"}],staticClass:"text-danger text-sm"},[_vm._v(" The GitHub email address field is required ")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_vm._m(7),_c('b-form-checkbox-group',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
                  'is-invalid': _vm.submitted && _vm.$v.updatef_userrole.$error,
                },attrs:{"options":_vm.optionsRoles,"name":"updatef_userrole","type":"text","id":"updatef_userrole","value":"true","multiple":true},model:{value:(_vm.formData.userRole),callback:function ($$v) {_vm.$set(_vm.formData, "userRole", $$v)},expression:"formData.userRole"}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('updatef_userrole')),expression:"errors.has('updatef_userrole')"}],staticClass:"text-danger text-sm"},[_vm._v(" The user roles field is required ")])],1)])]),_c('hr'),_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-primary btn-sm waves-effect waves-light",attrs:{"type":"submit"}},[_vm._v(" Save ")]),_vm._v("    "),_c('button',{staticClass:"btn btn-light btn-sm waves-effect waves-light",on:{"click":function($event){return _vm.closeUpdateUser()}}},[_vm._v(" Cancel ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"updatef_username"}},[_vm._v("Username "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"updatef_collaborators"}},[_vm._v("Collaborators "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"updatef_projects"}},[_vm._v("Projects "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"updatef_admin"}},[_vm._v("Admin "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"updatef_email"}},[_vm._v("Email Address "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"updatef_githubUsername"}},[_vm._v("Github Username "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"updatef_githubEmail"}},[_vm._v("GitHub Email Address "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"updatef_userrole"}},[_vm._v("User Role "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
}]

export { render, staticRenderFns }