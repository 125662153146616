import Dashboard from "./index";
import Releasetable from "./releasetable.vue"
import Recentnotificationstable from "./recentnotificationstable.vue"
export default [
  {
    // Define the path for the "dashboard" route
    path: "/dashboard",
    // Specify the component to be rendered for this route
    component: Dashboard,
    // Assign a name to the route, which can be used for navigation and programmatic routing
    name: "Dashboard",
  },
  {
    // Define the path for the "dashboard" route
    path: "/releasetable",
    // Specify the component to be rendered for this route
    component: Releasetable,
    // Assign a name to the route, which can be used for navigation and programmatic routing
    name: "Releasetable",
  }
  ,
  {
    // Define the path for the "dashboard" route
    path: "/recentnotificationstable",
    // Specify the component to be rendered for this route
    component: Recentnotificationstable,
    // Assign a name to the route, which can be used for navigation and programmatic routing
    name: "RecentNotificationstable",
  }
];
