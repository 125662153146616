var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"authentication-page-content p-4 d-flex align-items-center min-vh-100"},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-9"},[_c('div',[_vm._m(1),_c('b-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAuthenticate),expression:"isAuthenticate"}],attrs:{"show":"","dismissible":"","variant":"danger"}},[_vm._v("Invalid Credentials!")]),(_vm.isVerify === false)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_vm._v(" Token Expired! If you want to generate a new password reset link, click on "),_c('router-link',{attrs:{"tag":"a","to":"/forgotpassword"}},[_c('i',{staticClass:"mdi mdi-lock mr-1"}),_vm._v(" Forgot your password ")])],1):_c('div',{staticClass:"p-2 mt-5"},[_c('form',{ref:"form",staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();return _vm.resetPassword.apply(null, arguments)}}},[_c('div',{staticClass:"form-group auth-form-group-custom mb-4"},[_c('i',{staticClass:"ri-lock-2-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"password"}},[_vm._v("New Password")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required|verify_password'),expression:"'required|verify_password'"},{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],ref:"password",staticClass:"form-control",class:{
                              'is-invalid': _vm.submitted && _vm.$v.password.$error,
                            },attrs:{"name":"password","type":"password","id":"password","placeholder":"Enter new password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(
                              _vm.errors.has('password') &&
                              _vm._.find(_vm.errors.items, { field: 'password' })
                                .rule == 'required'
                            ),expression:"\n                              errors.has('password') &&\n                              _.find(errors.items, { field: 'password' })\n                                .rule == 'required'\n                            "}],staticClass:"text-danger text-sm"},[_c('span',[_vm._v("The new password field is required")])]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(
                              _vm.errors.has('password') &&
                              _vm._.find(_vm.errors.items, { field: 'password' })
                                .rule == 'verify_password'
                            ),expression:"\n                              errors.has('password') &&\n                              _.find(errors.items, { field: 'password' })\n                                .rule == 'verify_password'\n                            "}],staticClass:"text-danger text-sm"},[_vm._v(" "+_vm._s(_vm.errors.first("password"))+" ")])]),_c('div',{staticClass:"form-group auth-form-group-custom mb-4"},[_c('i',{staticClass:"ri-lock-2-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"confirmPassword"}},[_vm._v("Confirm Password")]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required|confirmed:password'),expression:"'required|confirmed:password'"},{name:"model",rawName:"v-model",value:(_vm.password_confirmation),expression:"password_confirmation"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.password_confirmation.$error,
                            },attrs:{"name":"password_confirmation","type":"password","id":"password_confirmation","placeholder":"Enter password"},domProps:{"value":(_vm.password_confirmation)},on:{"input":function($event){if($event.target.composing)return;_vm.password_confirmation=$event.target.value}}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('password_confirmation')),expression:"errors.has('password_confirmation')"}],staticClass:"text-danger text-sm"},[_vm._v(" The password confirmation field does not match ")])]),_c('div',{staticClass:"mt-4 text-center"},[_c('button',{staticClass:"btn btn-sm btn-primary w-md waves-effect waves-light",attrs:{"type":"submit"}},[_vm._v(" Reset Password ")])])])]),_c('div',{staticClass:"mt-5 text-center"},[_c('p',[_vm._v(" Copyright © "+_vm._s(new Date().getFullYear())+" "),_c('a',{attrs:{"href":"https://metapercept.com/","target":"_blank"}},[_vm._v(" Metapercept Technology Services LLP ")]),_vm._v(" All Rights Reserved ")])])],1)])])])])]),_vm._m(2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-btn btn-sm d-none d-sm-block"},[_c('a',{attrs:{"href":"/"}},[_c('i',{staticClass:"mdi mdi-home-variant h2 text-white"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"font-size-18 mt-4"},[_vm._v("Welcome Back !")]),_c('p',{staticClass:"text-muted"},[_vm._v(" Reset Password to continue to metR Client Admin. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"authentication-bg"},[_c('div',{staticClass:"bg-overlay"})])])
}]

export { render, staticRenderFns }