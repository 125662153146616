import Admins from "./index";
// Import middlewares (if any)
export default [
  {
    // Define the path for the "admins" route
    path: "/admins",
    // Specify the component to be rendered for this route
    component: Admins,
    // Assign a name to the route, which can be used for navigation and programmatic routing
    name: "Admins",
    // You can also define middleware here if needed
  },
];
