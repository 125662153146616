var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageHeader',{attrs:{"icon":'mdi mdi-office-building h3',"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[(!_vm._.isEmpty(this.orgDetails))?_c('div',{staticClass:"card-body"},[_c('div',{attrs:{"role":"tablist"}},[_c('b-card',{staticClass:"mb-2 shadow custom-card-border",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"custom-b-card-header",attrs:{"header-tag":"header","role":"tab"}},[_c('h6',{staticClass:"m-0"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-4",modifiers:{"accordion-4":true}}],staticClass:"text-dark",attrs:{"href":"javascript: void(0);"}},[_vm._v("General details")])])]),_c('b-collapse',{attrs:{"id":"accordion-4","visible":"","accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',[_c('b-card-text',[_c('p',[_c('b',[_vm._v("Organization Id :")]),_vm._v(" "+_vm._s(this.orgDetails.customId)+" ")]),_c('p',[_c('b',[_vm._v("Organization Name : ")]),_vm._v(_vm._s(this.orgDetails.name)+" ")]),_c('p',[_c('b',[_vm._v("Organization Email Address : ")]),_vm._v(_vm._s(this.orgDetails.email)+" ")]),_c('p',[_c('b',[_vm._v("Subdomain Name : ")]),_vm._v(" "+_vm._s(this.orgDetails.accessURL)+" ")])])],1)],1)],1),_c('b-card',{staticClass:"mb-2 shadow custom-card-border",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"custom-b-card-header",attrs:{"header-tag":"header","role":"tab"}},[_c('h6',{staticClass:"m-0"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-1",modifiers:{"accordion-1":true}}],staticClass:"text-dark",attrs:{"href":"javascript: void(0);"}},[_vm._v("Subscription Plan")])])]),_c('b-collapse',{attrs:{"id":"accordion-1","visible":"","accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',[_c('b-card-text',[(
                        this.orgSubscriptionPlanDetails &&
                        this.orgSubscriptionPlanDetails.subscriptionPlanRef &&
                        this.orgSubscriptionPlanDetails.subscriptionPlanRef[0]
                      )?_c('p',[_c('b',[_vm._v("Plan :")]),_vm._v(" "+_vm._s(this.orgSubscriptionPlanDetails.subscriptionPlanRef[0] .title)+" ")]):_vm._e(),_c('p',[_c('b',[_vm._v("Subscribed At :")]),_vm._v(" "+_vm._s(_vm.getFormattedDatesubscribedAt())+" ")]),_c('p',[_c('b',[_vm._v("Plan Expiry : ")]),_vm._v(_vm._s(_vm.getFormattedDate()))]),(
                        this.orgSubscriptionPlanDetails &&
                        this.orgSubscriptionPlanDetails.subscriptionPlanRef &&
                        this.orgSubscriptionPlanDetails.subscriptionPlanRef[0]
                      )?_c('p',[_c('b',[_vm._v("Admin : ")]),_vm._v(_vm._s(this.orgSubscriptionPlanDetails.subscriptionPlanRef[0] .adminCount)+" ")]):_vm._e(),(
                        this.orgSubscriptionPlanDetails &&
                        this.orgSubscriptionPlanDetails.subscriptionPlanRef &&
                        this.orgSubscriptionPlanDetails.subscriptionPlanRef[0]
                      )?_c('p',[_c('b',[_vm._v("Users : ")]),_vm._v(_vm._s(this.orgSubscriptionPlanDetails.subscriptionPlanRef[0] .userCount)+" ")]):_vm._e()])],1)],1)],1),_c('b-card',{staticClass:"mb-2 shadow custom-card-border",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"custom-b-card-header",attrs:{"header-tag":"header","role":"tab"}},[_c('h6',{staticClass:"m-0"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-2",modifiers:{"accordion-2":true}}],staticClass:"text-dark",attrs:{"href":"javascript: void(0);"}},[_vm._v("User Details")])])]),_c('b-collapse',{attrs:{"id":"accordion-2","accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',[_c('b-card-text',[_c('p',[_c('b',[_vm._v("Users :")]),_vm._v(" "+_vm._s(this.orgUserActive.length + this.orgUserInactive.length)+" ")]),_c('p',[_c('b',[_vm._v("Admin : ")]),_vm._v(" "+_vm._s(this.orgAdmin.length))]),_c('p',[_c('b',[_vm._v("Active Users : ")]),_vm._v(" "+_vm._s(this.orgUserActive.length)+" ")]),_c('p',[_c('b',[_vm._v("Inactive Users : ")]),_vm._v(" "+_vm._s(this.orgUserInactive.length)+" ")])])],1)],1)],1),_c('b-card',{staticClass:"mb-2 shadow custom-card-border",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"custom-b-card-header",attrs:{"header-tag":"header","role":"tab"}},[_c('h6',{staticClass:"m-0"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-3",modifiers:{"accordion-3":true}}],staticClass:"text-dark",attrs:{"href":"javascript: void(0);"}},[_vm._v("Vm's Specification")])])]),_c('b-collapse',{attrs:{"id":"accordion-3","accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',[_c('b-card-text',[_c('p',[_c('b',[_vm._v("Vm Count : ")]),_vm._v(" 01")]),_c('p',[_c('b',[_vm._v("CPU : ")]),_vm._v(" 1 vCPU")]),_c('p',[_c('b',[_vm._v("Memory : ")]),_vm._v(" 614 MB")]),_c('p',[_c('b',[_vm._v("Basic Charges : ")]),_vm._v(" 01")])])],1)],1)],1)],1)]):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }