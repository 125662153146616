import Login from "./login";
import ChangePassword from "./changepassword";
import VerifyAccount from "./verifyaccount";
import ForgotPassword from "./forgotpassword";
import ResetPassword from "./resetpassword";
export default [
  {
    // Login Route
    path: "/",
    component: Login,
    name: "Login",
    beforeEnter: (to, from, next) => {
      // Check if the user has a token and a session ID in local storage
      const Ctoken = localStorage.getItem('Ctoken');
      if (Ctoken) {
        // Redirect to the dashboard if the user has a token
        next('/dashboard');
      } else {
        // Allow navigation to the login page
        next();
      }
    }
  },
  {
    // Change Password Route
    path: "/changepassword",
    component: ChangePassword,
    name: "ChangePassword",
  },
  {
    // Verify Account Route
    path: "/verify/:token",
    component: VerifyAccount,
    name: "VerifyAccount",
  },
  {
    // Forgot Password Route
    path: "/forgotpassword",
    component: ForgotPassword,
    name: "forgotpassword",
  },
  {
    // Reset Password Route
    path: "/resetpassword/:token",
    component: ResetPassword,
    name: "resetpassword",
  }
];
