<template>
  <div>
    <!-- Page Header Component -->
    <PageHeader :icon="'ri-dashboard-line h3'" :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-8">
        <!-- Stat Component for displaying statistics -->
        <Stat />
        <ReleaseLog />
      </div>
      <div class="col-xl-4">
        <!-- RecentNotification Component for displaying recent notifications -->
        <RecentNotification />
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from "@/components/pageheader";
import Stat from "./widget";
import RecentNotification from "./recentnotification";
import ReleaseLog from "./releaselog";
import { eventBus } from "../../../main";
export default {
  components: {
    PageHeader,
    Stat,
    RecentNotification,
    ReleaseLog,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Dashboard",
          active: true,
        },
      ],
    };
  },
  mounted() {
    eventBus.$emit("update-sidebar", "menuitems.dashboard.text");
  },
  created() {
    if (!this.$store.state.Auth.gitToken) {
      this.$router.push({
        name: "Projects",
      });
    }
  },
};
</script>
