<template>
  <div>
    <!-- Page Header Component -->
    <PageHeader :icon="'mdi mdi-puzzle h3'" :title="title" :items="items" />
    <div v-if="!customPluginFlag" class="container mt-5">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <!-- Display message when custom plugin is not enabled -->
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">
                <i class="mdi mdi-lock"></i>
                Access to Custom Plugin Feature
              </h5>
              <!-- Message for users without custom plugin access -->
              <p class="card-text">Dear User,</p>
              <!-- Explanation about the custom plugin feature -->
              <p class="card-text">
                We understand that you currently do not have access to our
                custom plugin feature. This feature provides additional
                functionality that can enhance your experience on our platform.
              </p>
              <!-- Contact information for enabling the feature -->
              <p class="card-text">
                To enable this feature, please contact our support team at
                <a href="mailto:support@metr.com">support@metr.com</a>.
              </p>
              <!-- Thank you message -->
              <p class="card-text">
                Our team will assist you in enabling the custom plugin feature
                and ensure you can enjoy its benefits and content.
              </p>
              <p class="card-text">
                Thank you for using our platform, and we look forward to helping
                you gain access to this feature!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <!-- Display plugin details when custom plugin is enabled -->
      <div v-if="pluginDetails.length">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <!-- Button for adding a new plugin -->
                      <button class="btn btn-sm btn-primary" disabled>
                        Add New Plugin
                      </button>
                    </div>
                  </div>
                </div>
                <!-- Table for displaying plugin details -->
                <div class="table-responsive mb-0">
                  <b-table
                    :items="pluginDetails"
                    :fields="colfields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    :filter-function="filterTable"
                    ref="adminTable"
                    show-empty
                  >
                    <!-- Custom formatting for the 'updatedAt' column -->
                    <template #cell(updatedAt)="row">
                      {{ formatUpdatedAt(row.item.updatedAt) }}
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div
                    class="dataTables_paginate paging_simple_numbers col justify-content-center row-count-cust-cls"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- Row count of total plugin details -->
                      <span class="py-2">
                        Total Rows: {{ this.pluginDetails.length }}</span
                      >
                    </ul>
                  </div>
                  <div class="row-pagination-cust-cls">
                    <ul class="pagination-rounded mb-0">
                      <!-- Pagination controls -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!pluginDetails.length">
        <!-- Display when there are no plugin details -->
        <b-modal
          id="modal-progress"
          ref="modaloutputprogress"
          title="Processing"
          title-class="font-18"
          hide-header
          hide-footer
          hide-close
          no-close-on-backdrop
          no-close-on-esc
        >
          <p>
            Please wait while your file is being uploaded. Thank you for your
            patience.
          </p>
          <b-progress
            :value="progress"
            :max="100"
            class="custom-progress"
          ></b-progress>
        </b-modal>
        <div class="card">
          <div class="card-body">
            <!-- Form for adding a new plugin -->
            <div class="form-group">
              <label class="control-label"
                >Select plugin<span class="text-danger">*</span></label
              >
              <multiselect v-model="selectplugin" :options="pluginOptions">
              </multiselect>
            </div>
            <div>
              <div class="from-group">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="control-label"
                        >Transtype<span class="text-danger">*</span></label
                      >
                      <div class="d-flex justify-content-between">
                        <input
                          placeholder="Enter transaction type value"
                          type="text"
                          required
                          class="form-control mr-1"
                          v-model="newItem"
                          @keyup.enter="addItem"
                        />
                        <button
                          class="btn btn-primary text-nowrap ml-auto btn-sm"
                          @click="addItem"
                        >
                          Add Item
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-responsive" v-if="transtypeList.length">
                <div class="max-height-table">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Transtype</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- Display the transaction types and remove action -->
                      <tr v-for="(item, index) in transtypeList" :key="index">
                        <td>{{ item }}</td>
                        <td>
                          <button
                            @click="removeItem(index)"
                            class="btn btn-sm btn-primary"
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div>
                <h6>Upload zip File<span class="text-danger">*</span></h6>
              </div>
              <!-- Form for uploading a zip file -->
              <form @submit.prevent="submitForm" enctype="multipart/form-data">
                <div
                  class="drop-zone"
                  :class="{ dragging: dragging }"
                  @dragover.prevent
                  @dragenter="dragging = true"
                  @dragleave="dragging = false"
                  @drop="handleDrop"
                >
                  <div class="drop-zone-inner">
                    <div v-if="!fileName" class="text-center">
                      <i class="ri-file-zip-line ri-4x word-icon"></i>
                      <p class="mt-2">Drag and drop your zip file here</p>
                    </div>
                    <div v-if="fileName" class="text-center">
                      <i class="ri-file-word-2-line ri-4x word-icon"></i>
                    </div>
                    <div
                      v-if="fileName"
                      class="alert alert-success m-2"
                      role="alert"
                    >
                      <p v-if="fileName">Selected file : {{ fileName }}</p>
                    </div>
                  </div>
                  <input
                    v-if="!fileName"
                    type="file"
                    @change="handleFileInput"
                    class="d-none"
                    ref="fileInput"
                    name="zipFile"
                    accept=".zip"
                  />
                  <button
                    v-if="!fileName"
                    type="button"
                    class="btn btn-sm btn-primary mb-2"
                    @click="openFileInput"
                  >
                    Browse File to Upload
                  </button>
                </div>
                <div class="text-center pt-2">
                  <p v-if="!fileName">Accepted file type: .zip only</p>
                  <button
                    type="button"
                    class="btn btn-sm btn-primary mr-3"
                    :disabled="!fileSelected"
                    @click="sendFileToServer"
                  >
                    <i class="fa fa-fw fa-upload me-1"></i> Upload
                  </button>
                  <button
                    type="button"
                    class="btn btn-sm btn-light"
                    @click="cancelForm"
                  >
                    <i class="fa fa-fw fa-times me-1"></i>Reset
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import moment from "moment";
import _ from "lodash";
import PageHeader from "@/components/pageheader";
import { eventBus } from "../../../main";
import { workspacePath } from "@/api/global.env.js";
export default {
  data() {
    return {
      title: "Custom Plugin",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Custom Plugin",
          active: true,
        },
      ],
      sortBy: "",
      filter: null,
      totalRows: 1,
      rows: 1,
      sortDesc: true,
      currentPage: 1,
      perPage: 10,
      progress: 0,
      pageOptions: [10, 25, 50, 100],
      newItem: "",
      transtypeList: [],
      filterOn: [],
      customPluginFlag: false,
      dragging: false,
      file: null,
      fileName: null,
      fileSelected: false,
      uploadPercentage: 0,
      selectplugin: "DITA-OT with custom plugin",
      pluginOptions: ["DITA-OT with custom plugin"],
      pluginDetails: [],
      colfields: [
        {
          key: "parentFolder",
          label: "Plugin Folder",
          sortable: false,
        },
        {
          key: "transtype",
          label: "Transtype",
          sortable: false,
        },
        {
          key: "updatedAt",
          label: "Updated At",
          sortable: false,
        },
      ],
    };
  },
  components: {
    Multiselect,
    PageHeader,
  },
  created() {
    if (!this.$store.state.Auth.gitToken) {
      this.$router.push({
        name: "Projects",
      });
    }
  },
  mounted() {
    eventBus.$emit("update-sidebar", "menuitems.customplugin.text");
    if (this.$store.state.Auth.pluginDetails.length) {
      this.pluginDetails = this.$store.state.Auth.pluginDetails;
    } else {
      this.getPluginDetails();
    }
    if (this.$store.state.Auth.customPlugin) {
      this.customPluginFlag = this.$store.state.Auth.customPlugin;
    } else {
      this.getOrganizationData();
    }
  },
  computed: {
    _() {
      return _;
    },
  },
  methods: {
    moment,
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    addItem() {
      if (this.newItem.trim() !== "") {
        this.transtypeList.push(this.newItem);
        this.newItem = "";
      }
    },
    async getOrganizationData() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get("/serveradmin/organization/")
        .then((response) => {
          loader.hide();
          if (response.data) {
            const org = response.data.find(
              (item) => item.id === this.$store.state.Auth.orgId
            );
            if (org) {
              this.customPluginFlag = org.customPlugin;
              this.$store.commit("setCustomPlugin", org.customPlugin);
            } else {
              this.messageToast(
                "Error",
                "danger",
                "No organization details found"
              );
            }
          } else {
            this.messageToast(
              "Error",
              "danger",
              "Failed to retrieve organization data"
            );
          }
        })
        .catch(() => {
          loader.hide();
          this.messageToast(
            "Error",
            "danger",
            "An error occurred while fetching organization data"
          );
        });
    },
    async getPluginDetails() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      await this.$store.getters.client
        .get(`/orguser/docpublisher/customplugin`)
        .then((res) => {
          loader.hide();
          if (res.data) {
            this.pluginDetails = res.data;
            this.$store.commit("setPluginDetails", res.data);
          } else {
            this.messageToast("Error", "danger", "No plugin details found");
          }
        })
        .catch(() => {
          loader.hide();
          this.messageToast(
            "Error",
            "danger",
            "An error occurred while fetching plugin details"
          );
        });
    },
    formatUpdatedAt(updatedAt) {
      return moment(updatedAt).format("YYYY-MM-DD");
    },
    filterTable(row, filter) {
      const filterUpperCase = filter.toUpperCase();
      const hasNameMatch = row.name.toUpperCase().startsWith(filterUpperCase);
      return hasNameMatch;
    },
    removeItem(index) {
      this.transtypeList.splice(index, 1);
    },
    handleDrop(e) {
      e.preventDefault();
      this.dragging = false;
      const file = e.dataTransfer.files[0];
      if (file) {
        if (file.type.includes("zip")) {
          this.file = file;
          this.fileName = file.name;
          this.fileSelected = true;
        } else {
          this.messageToast(
            "Error",
            "danger",
            "Please select a valid ZIP file."
          );
          this.file = null;
          this.fileName = null;
          this.fileSelected = false;
        }
      }
    },
    handleFileInput(e) {
      const file = e.target.files[0];
      if (file) {
        if (file.type.includes("zip")) {
          this.file = file;
          this.fileName = file.name;
          this.fileSelected = true;
        } else {
          this.messageToast(
            "Error",
            "danger",
            "Please select a valid ZIP file."
          );
          e.target.value = null;
          this.fileSelected = false;
        }
      }
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    sendFileToServer() {
      this.progress = 0;
      const formData = new FormData();
      const isCustomDitaOt = this.selectplugin === "DITA-OT with custom plugin";
      formData.append(
        isCustomDitaOt ? "customDitaOt" : "customPlugin",
        this.file
      );
      const WorkspacePath = `${workspacePath}`;
      this.$refs["modaloutputprogress"].show();
      const progressInterval = setInterval(() => {
        if (this.progress < 99) {
          this.progress += 1;
        }
      }, 500);
      const endpoint = isCustomDitaOt
        ? "uploadCustomDitaOt"
        : "uploadCustomPlugin";
      this.$store.getters.client
        .post(
          `/orgadmin/customPlugin/${endpoint}?workspacePath=${WorkspacePath}&transtype=${this.transtypeList}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          clearInterval(progressInterval);
          this.progress = 100;

          this.handleFileUploadSuccess();
        })
        .catch((error) => {
          this.handleFileUploadError(error);
        });
    },
    handleFileUploadSuccess() {
      this.getPluginDetails();
      this.clearFormValues();
      const successMessage = "Zip file uploaded successfully";
      this.$refs["modaloutputprogress"].hide();
      this.fileSelected = true;
      this.messageToast("Success", "success", successMessage);
    },
    handleFileUploadError(error) {
      this.$refs["modaloutputprogress"].hide();
      this.clearFormValues();
      const errorMessage = error.response
        ? error.response.data.message
        : "An error occurred";
      this.messageToast("Invalid request", "danger", errorMessage);
    },

    clearFormValues() {
      this.file = null;
      this.transtypeList = [];
      this.transtype = "";
      this.dragging = false;
      this.fileName = null;
      this.formData = null;
      this.fileSelected = true;
    },
    submitForm() {
      if (this.file) {
        this.sendFileToServer();
      }
    },
    cancelForm() {
      this.file = null;
      this.fileName = null;
      this.dragging = false;
      this.formData = null;
      this.fileSelected = false;
    },
    hideModal() {
      window.location.reload();
    },
  },
};
</script>
<style scoped>
label {
  font-weight: 400;
}
.custom-progress {
  height: 5px;
}
.drop-zone {
  border: 2px dashed #ccc;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 200px;
}
.drop-zone-inner {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
div.card .card-body .form-group .drop-zone.dragging {
  border: 2px dashed #ccc;
  background-color: #f0f0f0;
}
.drop-zone i {
  color: #ccc;
}
.drop-zone p {
  margin-top: 0.5rem;
  font-size: 1rem;
}
.btn-secondaryBtn {
  background: #252b3b !important;
  color: #fff !important;
}

.card-icons-btn:hover {
  color: #fff;
}
.progress {
  height: 20px;
}
.progress-bar {
  background-color: #007bff;
}
.max-height-table {
  max-height: 20vh;
  overflow-y: auto;
}
</style>
