<template>
  <div>
    <!-- Header with Tabs -->
    <div class="cmh-header">
      <!-- Tab component -->
      <b-tabs
        justified
        nav-class="nav-tabs-custom"
        content-class="text-muted"
        class="btabs-height bg-white"
      >
        <!-- First Tab: Active Users -->
        <b-tab title="Active Users" active>
          <!-- Content for the Active Users tab, which includes the ActiveUsers component -->
          <ActiveUsers />
        </b-tab>
        <!-- Second Tab: Inactive Users -->
        <b-tab title="Inactive Users">
          <!-- Content for the Inactive Users tab, which includes the InactiveUsers component -->
          <InactiveUsers />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import ActiveUsers from "./active.vue";
import InactiveUsers from "./inactive.vue";
export default {
  components: {
    ActiveUsers,
    InactiveUsers,
  },
  data() {
    return {};
  },
  created() {
    if (!this.$store.state.Auth.gitToken) {
      this.$router.push({
        name: "Projects",
      });
    }
  },
  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
  },
};
</script>
<style scoped>
.cmh-tab-title {
  font-weight: 900;
  color: blue;
}
</style>
