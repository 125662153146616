<template>
<div>
    <PageHeader :icon="'mdi mdi-account-supervisor h3'" :title="title" :items="items" />
    <Details />
</div>

</template>

<script>
import PageHeader from '../../../components/pageheader.vue';
import Details from './components/details.vue';
import {eventBus} from '../../../main';
export default {
    components: {
        PageHeader,
        Details
    },
    data() {
        return {
            // Title for the page header
            title: "Admins",
            // Breadcrumbs items for navigation
            items: [{
                    text: "Dashboard",
                    href: "/" 
                },
                {
                    text: "Admins",
                    active: true
                }
            ],
        };
    },
    mounted() {
        // Emit an event to update the sidebar based on the current page
        eventBus.$emit('update-sidebar', "menuitems.admins.text");
    }
};
</script>
