import organization from "./index";

// Import middlewares (if any)
export default [
  {
    // Define the path for the "organization" route
    path: "/organization",
    // Specify the component to be rendered for this route
    component: organization,
    // Assign a name to the route, which can be used for navigation and programmatic routing
    name: "organization",
  }
];
