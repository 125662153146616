import User from "./index";
import Useradd from "./useradd";
import Userupdate from "./updateuser";

// Import middlewares (if any)
export default [
  {
    // Define the path for the "users" route
    path: "/users",
    // Specify the component to be rendered for this route
    component: User,
    // Assign a name to the route, which can be used for navigation and programmatic routing
    name: "Users",
  },
  {
    // Define the path for the "useradd" route with a dynamic parameter ":id"
    path: "/useradd/:id",
    // Specify the component to be rendered for this route
    component: Useradd,
    // Assign a name to the route
    name: "Useradd",
    // Pass 'id' as a prop to the component using a function
    props: (route) => ({
      id: decodeURIComponent(route.params.id),
    }),
  },
  {
    // Define the path for the "userupdate" route with a dynamic parameter ":id"
    path: "/userupdate/:id",
    // Specify the component to be rendered for this route
    component: Userupdate,
    // Assign a name to the route
    name: "Userupdate",
    // Pass 'id' as a prop to the component using a function
    props: (route) => ({
      id: decodeURIComponent(route.params.id),
    }),
  },
];
