import { unauthClient, apiDefaults } from "@/api/index.js";
import axios from "axios";

import CryptoJS from "crypto-js";
import { secretKey } from "../../api/global.env";
import { handleApiError } from "../../apiErrorHandler";

const encryptData = (data) => {
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    secretKey
  ).toString();
  return encryptedData;
};
const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (error) {
    return null;
  }
};
const updateLocalStorage = (key, data) => {
  localStorage.setItem(key, encryptData(data));
};
const Auth = {
  state: () => ({
    token: decryptData(localStorage.getItem("Ctoken")) || null,
    refresh: decryptData(localStorage.getItem("refresh")) || null,
    sessionId: decryptData(localStorage.getItem("sessionId")) || null,
    adminEmail: decryptData(localStorage.getItem("adminEmail")) || null,
    adminName: decryptData(localStorage.getItem("adminName")) || null,
    unauthClient: unauthClient,
    userId: decryptData(localStorage.getItem("userId")) || null,
    orgId: decryptData(localStorage.getItem("orgId")) || null,
    gitToken: decryptData(localStorage.getItem("gitToken")) || null,
    isChangePassword:
      decryptData(localStorage.getItem("isChangePassword")) || null,
    name: decryptData(localStorage.getItem("name")) || null,
    githubUsername: decryptData(localStorage.getItem("githubUsername")) || null,
    isActive: decryptData(localStorage.getItem("isActive")) || null,
    isEmailVerified:
      decryptData(localStorage.getItem("isEmailVerified")) || null,
    endpoints: {
      obtain: "/orgadmin/authenticate",
      obtainGit: "/orgadmin/success",
    },
    recentNotification: [],
    recentreleaseLog: [],
    fileCount: "",
    projectsList: [],
    activeUserData: [],
    inActiveUserData: [],
    adminData: [],
    orgDetails: [],
    orgUserDetails: [],
    subPlanDtls: [],
    orgAdminDetails: [],
    profileData: [],
    customPlugin: "",
    pluginDetails: [],
  }),
  mutations: {
    updateTokens(state, tokens) {
      updateLocalStorage("Ctoken", tokens.token);
      updateLocalStorage("adminEmail", tokens.adminEmail);
      updateLocalStorage("adminName", tokens.adminName);
      updateLocalStorage("userId", tokens.userId);
      updateLocalStorage("orgId", tokens.orgId);
      updateLocalStorage("gitToken", tokens.gitToken);
      updateLocalStorage("isChangePassword", tokens.isChangePassword);
      updateLocalStorage("name", tokens.name);
      updateLocalStorage("githubUsername", tokens.githubUsername);
      state.tokensessionStorage = tokens.tokensessionStorage;
      state.token = tokens.token;
      state.adminEmail = tokens.adminEmail;
      state.adminName = tokens.adminName;
      state.refresh = tokens.refresh;
      state.userId = tokens.userId;
      state.orgId = tokens.orgId;
      state.gitToken = tokens.gitToken;
      state.name = tokens.adminName;
      state.githubUsername = tokens.githubUsername;
    },
    updateAccess(state, token) {
      updateLocalStorage("token", token);
      state.token = token;
    },
    updateSessionId(state, sessionId) {
      state.sessionId = sessionId;
      updateLocalStorage("sessionId", sessionId);
    },
    updateGitToken(state, token) {
      updateLocalStorage("gitToken", token.gitToken);
      state.gitToken = token.gitToken;
    },
    updateChangePasswordStatus(state, token) {
      updateLocalStorage("isChangePassword", token.isChangePassword);
      state.isChangePassword = token.isChangePassword;
    },
    updateActiveStatus(state, token) {
      updateLocalStorage("isActive", token.isActive);
      state.isActive = token.isActive;
    },
    updateEmailVerifiedStatus(state, token) {
      updateLocalStorage("isEmailVerified", token.isEmailVerified);
      state.isEmailVerified = token.isEmailVerified;
    },
    removeToken(state) {
      localStorage.setItem("Ctoken", null);
      localStorage.setItem("sessionId", null);
      localStorage.setItem("refresh", null);
      localStorage.setItem("adminName", null);
      localStorage.setItem("adminEmail", null);
      localStorage.setItem("userId", null);
      localStorage.setItem("orgId", null);
      localStorage.setItem("gitToken", null);
      localStorage.setItem("isChangePassword", null);
      localStorage.setItem("name", null);
      localStorage.setItem("githubUsername", null);
      localStorage.setItem("isActive", null);
      localStorage.setItem("isEmailVerified", null);
      state.token = null;
      state.sessionId = null;
      state.adminName = null;
      state.adminEmail = null;
      state.userId = null;
      state.orgId = null;
      state.gitToken = null;
      state.isChangePassword = null;
      state.name = null;
      state.githubUsername = null;
      state.isActive = null;
      state.isEmailVerified = null;
      (state.recentNotification = []),
        (state.recentreleaseLog = []),
        (state.fileCount = ""),
        (state.projectsList = []),
        (state.activeUserData = []),
        (state.adminData = []),
        (state.orgDetails = []),
        (state.orgUserDetails = []),
        (state.subPlanDtls = []),
        (state.orgAdminDetails = []),
        (state.profileData = []),
        (state.customPlugin = []),
        (state.pluginDetails = []);
    },
    setrecentNotification(state, data) {
      state.recentNotification = data;
    },
    setRecentReleaseLog(state, data) {
      state.recentreleaseLog = data;
    },
    setFileCount(state, data) {
      state.fileCount = data;
    },
    setProjectList(state, data) {
      state.projectsList = data;
    },
    setActiveUserCount(state, data) {
      state.activeUserData = data;
    },
    setInactiveUserCount(state, data) {
      state.inActiveUserData = data;
    },
    setAdminData(state, data) {
      state.adminData = data;
    },
    setOrgDetails(state, data) {
      state.orgDetails = data;
    },
    setOrgUserDetails(state, data) {
      state.orgUserDetails = data;
    },
    setSubPlanDtls(state, data) {
      state.subPlanDtls = data;
    },
    setOrgAdmin(state, data) {
      state.orgAdminDetails = data;
    },
    setProfileData(state, data) {
      state.profileData = data;
    },
    setCustomPlugin(state, data) {
      state.customPlugin = data;
    },
    setPluginDetails(state, data) {
      state.pluginDetails = data;
    },
  },
  getters: {
    client: (state) => {
      const apiClient = axios.create({
        ...apiDefaults,
        headers: {
          Authorization: `Bearer ${state.token}`,
          sessionId: state.sessionId,
          userId: state.userId,
          orgId: state.orgId,
          gitToken: `${state.gitToken}`,
        },
      });
      apiClient.interceptors.response.use(
        (response) => {
          // Return a successful response back to the calling service
          return response;
        },
        (error) => {
          handleApiError(error);

          // Return any error which is not due to authentication back to the calling service
          if (error.response.status !== 401) {
            return new Promise((resolve, reject) => {
              reject(error);
            });
          }
        }
      );
      return apiClient;
    },
  },
  actions: {
    obtainToken(context, authDetails) {
      return context.state.unauthClient
        .post(context.state.endpoints.obtain, authDetails)
        .then((response) => {
          this.commit("updateTokens", {
            token: response.data.token,
            tokensessionStorage: response.data.token,
            adminEmail: response.data.email,
            adminName: response.data.name,
            userId: response.data.id,
            orgId: response.data.orgId,
            githubUsername: response.data.githubUsername,
          });
          this.commit("updateEmailVerifiedStatus", {
            isEmailVerified: response.data.isEmailVerified,
          });
          this.commit("updateActiveStatus", {
            isActive: response.data.isActive,
          });
          this.commit("updateChangePasswordStatus", {
            isChangePassword: response.data.isChangePassword,
          });
          this.commit("updateGitToken", {
            gitToken: response.data.gitToken,
          });
        });
    },
    refreshToken(context) {
      const payload = {
        refresh: context.state.refresh,
      };
      context.state.unauthClient
        .post(context.state.endpoints.refresh, payload)
        .then((response) => {
          this.commit("updateAccess", response.data.token);
        })
        .catch(() => {});
    },
    registerUser(context, authDetails) {
      return context.state.unauthClient
        .post(context.state.endpoints.register, authDetails)
        .then(() => {});
    },
  },
};
export default Auth;
