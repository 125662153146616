import Profile from "./index";

// Import middlewares (if any)
export default [
  {
    // Define the path for the "profile" route
    path: "/profile",
    // Specify the component to be rendered for this route
    component: Profile,
    // Assign a name to the route, which can be used for navigation and programmatic routing
    name: "Profile",
  },
];
