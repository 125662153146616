import { URL } from "./global.env";
import { axios } from "./index";
import Swal from "sweetalert2";

export const logoutApplication = (userId) => {
  Swal.fire({
    title: "Logout",
    text: "Do you want to logout the application?",
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Logout",
  }).then(async (result) => {
    if (result.isConfirmed) {
      await axios.post(
        `${URL}api/logout`,
        {},
        {
          headers: { userid: userId },
        }
      );
      localStorage.clear();
      window.location.href = "/";
    }
  });
};
