import axios from "axios";
import { URL } from "@/api/global.env";
import { handleApiError } from "../apiErrorHandler";

const apiDefaults = {
  baseURL: `${URL}api`,
  timeout: 1000000,
};

const unauthClient = axios.create({
  baseURL: `${URL}api`,
  timeout: 1000000,
});

const defaultAxios = axios.create();

defaultAxios.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response;
  },
  (error) => {
    handleApiError(error);

    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

export { unauthClient, apiDefaults, defaultAxios as axios };
