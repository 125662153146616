import Customplugin from './customplugin.vue'

export default [
  {
    // Define the path for the "customplugin" route
    path: "/customplugin",
    // Specify the component to be rendered for this route
    component: Customplugin,
    // Assign a name to the route, which can be used for navigation and programmatic routing
    name: 'customplugin',
  }
];
