import Git from "./index";
import Repo from "./repo.vue";
// Vue Router routes for your application
export default [
  {
    // Route for the "Projects" view
    path: "/github", // URL path
    component: Git, // Component to be rendered
    name: "Projects", // Route name
  },
  {
    // Route for individual repository view
    path: '/github/repo/:id', // URL path with dynamic parameter ':id'
    component: Repo, // Component to be rendered
    name: 'github/repo', // Route name
    props: (route) => ({
      id: decodeURIComponent(route.params.id), // Pass 'id' as a prop to the component
    }),
  }
];
