var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('PageHeader',{attrs:{"icon":'ri-customer-service-fill h3',"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-sm-12 col-lg-7 card"},[_c('form',{staticClass:"col-md-12",on:{"submit":function($event){$event.preventDefault();return _vm.sendContactEmail()}}},[_c('h2',{staticClass:"h1-responsive font-weight-bold text-center my-4"},[_vm._v(" Contact us ")]),_c('p',{staticClass:"text-center mx-auto mb-5"},[_vm._v(" Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to you within a matter of hours to help you. ")]),_c('div',{staticClass:"form-group"},[_vm._m(0),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.formData.name),expression:"formData.name"}],staticClass:"form-control",class:{ 'is-invalid': _vm.submitted && _vm.$v.name.$error },attrs:{"required":"","name":"name","type":"text","id":"name","placeholder":"Enter organization name"},domProps:{"value":(_vm.formData.name)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;return _vm.preventLeadingSpace.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "name", $event.target.value)}}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.errors.has('name') &&
                _vm._.find(_vm.errors.items, { field: 'name' }).rule == 'required'
              ),expression:"\n                errors.has('name') &&\n                _.find(errors.items, { field: 'name' }).rule == 'required'\n              "}],staticClass:"text-danger text-sm"},[_c('span',[_vm._v("Organization name field is required")])])]),_c('div',{staticClass:"form-group"},[_vm._m(1),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.formData.username),expression:"formData.username"}],staticClass:"form-control",class:{ 'is-invalid': _vm.submitted && _vm.$v.username.$error },attrs:{"required":"","name":"username","type":"text","id":"username","placeholder":"Enter username"},domProps:{"value":(_vm.formData.username)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;return _vm.preventLeadingSpace.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "username", $event.target.value)}}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.errors.has('username') &&
                _vm._.find(_vm.errors.items, { field: 'username' }).rule == 'required'
              ),expression:"\n                errors.has('username') &&\n                _.find(errors.items, { field: 'username' }).rule == 'required'\n              "}],staticClass:"text-danger text-sm"},[_c('span',[_vm._v("Username field is required")])])]),_c('div',{staticClass:"form-group"},[_vm._m(2),_c('textarea',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.formData.message),expression:"formData.message"}],staticClass:"form-control",class:{ 'is-invalid': _vm.submitted && _vm.$v.message.$error },attrs:{"required":"","name":"message","id":"message","rows":"4","placeholder":"Enter your message here"},domProps:{"value":(_vm.formData.message)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;return _vm.preventLeadingSpace.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "message", $event.target.value)}}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.errors.has('message') &&
                _vm._.find(_vm.errors.items, { field: 'message' }).rule == 'required'
              ),expression:"\n                errors.has('message') &&\n                _.find(errors.items, { field: 'message' }).rule == 'required'\n              "}],staticClass:"text-danger text-sm"},[_c('span',[_vm._v("Message field is required")])])]),_vm._m(3)])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"text-primary",attrs:{"for":"name"}},[_vm._v("Organization name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"text-primary",attrs:{"for":"username"}},[_vm._v("Username"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"text-primary",attrs:{"for":"email_body"}},[_vm._v("Write message"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group mb-0 d-flex justify-content-end mb-3"},[_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"submit"}},[_vm._v(" Send Message ")])])])
}]

export { render, staticRenderFns }