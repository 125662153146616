<template>
  <div>
    <PageHeader :icon="'mdi mdi-note-text h3'" :title="title" :items="items" />
    <div>
      <!-- Main Card -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <!-- Filter and Per Page Options -->
              <div class="row mt-2 d-flex justify-content-between mx-3">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
                <div
                  class="d-flex align-items-center justify-content-between mb-3"
                >
                  <div class="search-box mr-3">
                    <div class="position-relative">
                      <input
                        type="text"
                        class="form-control text-primary rounded custom-input-box"
                        placeholder="Search"
                        id="myInput"
                        v-model="filter"
                      />
                      <i class="mdi mdi-magnify search-icon"></i>
                    </div>
                  </div>
                  <!-- Buttons -->
                  <b-button
                    size="sm"
                    class="export-button mr-2"
                    variant="light"
                    @click="exportToExcel"
                    >Export to Excel</b-button
                  >
                  <b-button
                    size="sm"
                    class="export-button"
                    variant="light"
                    @click="exportToCSV"
                    >Export to CSV</b-button
                  >
                </div>
              </div>
            </div>
            <!-- Data Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="activityData"
                :fields="colfields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :filter-function="filterTable"
                ref="adminTable"
                show-empty
              >
                <!-- Custom cell rendering for createdAt -->
                <template #cell(createdAt)="row">
                  {{
                    moment(row.item.createdAt).format("DD-MMMM-YYYY hh:mm A")
                  }}
                </template>
              </b-table>
            </div>
            <!-- Pagination and Total Rows Count -->
            <div class="col-12 border-top pt-3">
              <div class="d-flex justify-content-end">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="filteredData.length"
                  :per-page="perPage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import PageHeader from "@/components/pageheader";
import { handleApiError } from "../../../apiErrorHandler.js";
import { eventBus } from "../../../main";
import _ from "lodash";
import * as XLSX from "xlsx";
export default {
  data() {
    return {
      activityData: [],
      filteredData: [],
      title: "Recent Publication ",
      items: [
        {
          text: "Dashboard",
          href: `/dashboard`,
        },
        {
          text: "Recent Publications",
          active: true,
        },
      ],
      userId: this.$store.state.Auth.userId,
      orgId: this.$store.state.Auth.orgId,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: true,
      colfields: this.generateTableColumns(),
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Number of Releases",
            backgroundColor: "#f87979",
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  computed: {
    rows() {
      return this.activityData.length;
    },
    _() {
      return _;
    },
    totalPages() {
      return Math.ceil(this.rows / this.perPage);
    },
  },
  watch: {
    perPage: {
      handler() {
        this.currentPage = 1;
      },
    },
    activityData: {
      handler() {
        this.filteredData = this.activityData;
      },
    },
    filter: {
      handler() {
        this.filteredData = this.activityData?.filter((data) =>
          data.projectName.toUpperCase().includes(this.filter?.toUpperCase())
        );
      },
    },
  },
  components: {
    PageHeader,
  },
  created() {
    eventBus.$emit("update-sidebar", "menuitems.dashboard.text");
    if (this.$store.state.Auth.recentreleaseLog.length) {
      this.activityData = this.$store.state.Auth.recentreleaseLog;
    } else {
      this.getrecentlog();
    }
    this.prepareChartData();
  },
  methods: {
    moment,
    onFiltered(filteredItems) {
      // Update the totalRows to the length of the filtered items
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    prepareChartData() {
      const projects = {};
      // Count releases per project
      this.data.forEach((release) => {
        const projectName = release.projectName;
        projects[projectName] = (projects[projectName] || 0) + 1;
      });
      // Populate chart data
      this.chartData.labels = Object.keys(projects);
      this.chartData.datasets[0].data = Object.values(projects);
      // Update the chart
      this.$refs.barChart._chart.update();
    },
    generateTableColumns() {
      return [
        {
          key: "releaseTitle",
          label: "Release Title",
          sortable: true,
        },
        {
          key: "projectName",
          label: "Project Name",
          sortable: true,
        },
        {
          key: "releasedBy",
          label: "Released By",
          sortable: true,
        },
        {
          key: "outputFormat",
          label: "Output Format",
          sortable: true,
        },
        {
          key: "ditaotVersion",
          label: "DITA OT Version",
          sortable: true,
        },
        {
          key: "createdAt",
          label: "Created At",
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) =>
            moment(item.createdAt).format("DD-MMMM-YYYY hh:mm A"),
        },
      ];
    },
    // This method is use to generate error or success message
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    getrecentlog() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`/orgadmin/notification/releasedByLastWeek?orgId=${this.orgId}`)
        .then((response) => {
          this.$store.commit("setRecentReleaseLog", response.data);
          this.showSpinner = false;
          this.activityData = response.data;
          loader.hide();
        })
        .catch((error) => {
          loader.hide();
          handleApiError(error);
          this.showSpinner = false;
        });
    },
    async downloadZip(item) {
      let loader = this.$loading.show({
        loader: "dots",
      });
      try {
        const response = await this.$store.getters.client.get(
          `/orguser/release/download?releaseId=${item._id}`,
          {
            responseType: "arraybuffer",
          }
        );
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${item.releaseTitle}.zip`);
        document.body.appendChild(link);
        link.click();
        loader.hide();
        this.messageToast(
          "Success",
          "success",
          "Zip file has been downloaded successfully"
        );
      } catch (error) {
        loader.hide();
        this.messageToast("Invalid request", "danger", error.message);
      }
    },
    exportToExcel() {
      const dataToExport = this.filteredData.map((item) => ({
        "Release Title": item.releaseTitle,
        "Project Name": item.projectName,
        "Released By": item.releasedBy,
        "Output Format": item.outputFormat,
        "DITA OT Version": item.ditaotVersion,
        "Created At": moment(item.createdAt).format("DD-MMMM-YYYY hh:mm A"),
      }));
      const ws = XLSX.utils.json_to_sheet(dataToExport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      // Save the workbook as an Excel file
      XLSX.writeFile(wb, "table_data.xlsx");
    },
    exportToCSV() {
      const dataToExport = this.filteredData.map((item) => ({
        "Release Title": item.releaseTitle,
        "Project Name": item.projectName,
        "Released By": item.releasedBy,
        "Output Format": item.outputFormat,
        "DITA OT Version": item.ditaotVersion,
        "Created At": moment(item.createdAt).format("DD-MMMM-YYYY hh:mm A"),
      }));
      const ws = XLSX.utils.json_to_sheet(dataToExport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      // Save the workbook as a CSV file
      XLSX.writeFile(wb, "table_data.csv", {
        bookType: "csv",
      });
    },
    filterTable(row, filter) {
      const filterUpperCase = filter.toUpperCase();
      const hasNameMatch = row.projectName
        .toUpperCase()
        .includes(filterUpperCase);
      return hasNameMatch;
    },
  },
};
</script>
<style>
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .row-count-cust-cls {
    margin-bottom: 10px;
  }
  .row-pagination-cust-cls {
    margin-top: 20px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .row-count-cust-cls ul {
    margin-bottom: 10px;
    text-align: left !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
