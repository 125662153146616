<template>
  <div>
    <!-- Page header with title and icon -->
    <PageHeader
      :title="title"
      :icon="'mdi mdi-clipboard-multiple h2'"
      :items="items"
    />
    <!-- Tabbed content with justified style -->
    <div class="bg-white">
      <b-tabs
        justified
        nav-class="nav-tabs-custom"
        content-class="text-muted"
        class="btabs-height bg-white"
      >
        <!-- Users tab -->
        <b-tab title="Users" active>
          <!-- Display the "userRole" component with "projectData" prop -->
          <userRole :projectData="projectData" />
        </b-tab>
        <!-- Commit tab -->
        <b-tab title="Commit">
          <!-- Display the "committ" component with "projectData" prop -->
          <committ :projectData="projectData" />
        </b-tab>
        <!-- Release tab -->
        <b-tab title="Release">
          <!-- Display the "release" component without any props -->
          <release />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import committ from "@/components/admin/Repository/commit.vue";
import userRole from "@/components/admin/Repository/user.vue";
import release from "@/components/admin/Repository/release.vue";
import PageHeader from "@/components/pageheader";
import CryptoJS from "crypto-js";
import { secretKey } from "../../../api/global.env";
import { eventBus } from "../../../main";
export default {
  components: {
    committ,
    userRole,
    release,
    PageHeader,
  },
  data() {
    return {
      title: "",
      items: [
        {
          text: "Project",
          href: `/github`,
        },
      ],
      repodata: [],
      username: "",
      repocoldata: [],
      apiURL: process.env.VUE_APP_DATABASEURL,
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      repousername: "",
      repouseremail: "",
      projectData: "test",
      id: CryptoJS.AES.decrypt(this.$route.params.id, secretKey).toString(
        CryptoJS.enc.Utf8
      ),
    };
  },
  created() {
    eventBus.$emit("update-sidebar", "menuitems.projects.text");
    this.getProjectDetails();
  },
  mounted() {},
  methods: {
    getProjectDetails() {
      this.$store.getters.client
        .get(`project/byprojectid?projectId=${this.id}`)
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.projectData = res.data[0];
            this.title = res.data[0].projectName;
            this.items.push({
              text: res.data[0].projectName,
              active: true,
            });
          } else {
            // Handle the case where res.data is empty
            this.messageToast(
              "Error",
              "danger",
              "No data found for the specified project ID"
            );
          }
        })
        .catch((error) => {
          // Handle the error and display a message toast
          const errorMessage = error.response
            ? error.response.data.message
            : "An error occurred";
          this.messageToast(
            "Error",
            "danger",
            `Error on get project details: ${errorMessage}`
          );
        });
    },
  },
};
</script>
