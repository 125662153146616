<template>
  <div>
    <!-- Container for the component -->
    <div class="card">
      <div class="card-body">
        <form
          class="form-horizontal"
          @submit.prevent="updateUser"
          ref="formUpdateUser"
        >
          <!-- Form fields for updating user information -->
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="updatef_username"
                  >Username <span class="text-danger">*</span></label
                >
                <!-- Input field for updating username -->
                <input
                  v-validate="'required'"
                  v-model="formData.username"
                  name="updatef_username"
                  type="text"
                  class="form-control"
                  id="updatef_username"
                  placeholder="Enter username"
                  :class="{
                    'is-invalid': submitted && $v.updatef_username.$error,
                  }"
                />
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('updatef_username')"
                >
                  The username field is required
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="updatef_collaborators"
                  >Collaborators <span class="text-danger">*</span></label
                >
                <!-- Dropdown for selecting collaborators -->
                <b-form-select
                  :options="optionsCollaboratorlist"
                  v-validate="'required'"
                  v-model="selectedCollaborators"
                  name="updatef_collaborators"
                  type="text"
                  class="form-control"
                  id="updatef_collaborators"
                  placeholder="Select collaborators"
                  :class="{
                    'is-invalid': submitted && $v.updatef_collaborators.$error,
                  }"
                  disabled
                ></b-form-select>
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('updatef_collaborators')"
                >
                  The Collaborators field is required
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="updatef_projects"
                  >Projects <span class="text-danger">*</span></label
                >
                <!-- Multiselect component for selecting multiple projects -->
                <multiselect
                  v-validate="'required'"
                  name="updatef_projects"
                  id="updatef_projects"
                  :class="{
                    'is-invalid': submitted && $v.addf_projects.$error,
                  }"
                  v-model="seletedProject"
                  tag-placeholder="Add this as a new tag"
                  placeholder="Please select a project"
                  label="text"
                  track-by="value"
                  :options="optionsProjectlist"
                  :multiple="true"
                >
                </multiselect>
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('updatef_projects')"
                >
                  The projects field is required
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="updatef_admin"
                  >Admin <span class="text-danger">*</span></label
                >
                <!-- Dropdown for selecting an admin -->
                <b-form-select
                  :options="optionsAdmins"
                  v-validate="'required'"
                  v-model="selectedAdmin"
                  name="updatef_admin"
                  type="text"
                  class="form-control"
                  id="updatef_admin"
                  placeholder="Select admin"
                  :class="{
                    'is-invalid': submitted && $v.updatef_admin.$error,
                  }"
                ></b-form-select>
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('updatef_admin')"
                >
                  The admin field is required
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="updatef_email"
                  >Email Address <span class="text-danger">*</span></label
                >
                <!-- Input field for updating email address -->
                <input
                  v-validate="'required'"
                  v-model="formData.email"
                  name="updatef_email"
                  type="text"
                  class="form-control"
                  id="updatef_email"
                  placeholder="Enter email"
                  :class="{
                    'is-invalid': submitted && $v.updatef_email.$error,
                  }"
                />
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('updatef_email')"
                >
                  The email field is required
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="updatef_githubUsername"
                  >Github Username <span class="text-danger">*</span></label
                >
                <!-- Input field for updating GitHub username -->
                <input
                  v-validate="'required'"
                  v-model="formData.githubUsername"
                  name="updatef_githubUsername"
                  type="text"
                  class="form-control disable-cust-cls"
                  id="updatef_githubUsername"
                  placeholder="Enter GitHub Username"
                  :class="{
                    'is-invalid': submitted && $v.updatef_githubUsername.$error,
                  }"
                  disabled
                />
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('updatef_githubUsername')"
                >
                  The GitHub username field is required
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="updatef_githubEmail"
                  >GitHub Email Address
                  <span class="text-danger">*</span></label
                >
                <!-- Input field for updating GitHub email address -->
                <input
                  v-validate="'required'"
                  v-model="formData.githubEmail"
                  name="updatef_githubEmail"
                  type="text"
                  class="form-control"
                  id="updatef_githubEmail"
                  placeholder="Enter GitHub email"
                  :class="{
                    'is-invalid': submitted && $v.updatef_githubEmail.$error,
                  }"
                />
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('updatef_githubEmail')"
                >
                  The GitHub email address field is required
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="updatef_userrole"
                  >User Role <span class="text-danger">*</span></label
                >
                <!-- Checkbox group for selecting user roles -->
                <b-form-checkbox-group
                  :options="optionsRoles"
                  v-validate="'required'"
                  v-model="formData.userRole"
                  name="updatef_userrole"
                  type="text"
                  id="updatef_userrole"
                  value="true"
                  :class="{
                    'is-invalid': submitted && $v.updatef_userrole.$error,
                  }"
                  :multiple="true"
                ></b-form-checkbox-group>
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('updatef_userrole')"
                >
                  The user roles field is required
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div class="text-right">
            <!-- Save and Cancel buttons -->
            <button
              class="btn btn-primary btn-sm waves-effect waves-light"
              type="submit"
            >
              Save
            </button>
            &nbsp;&nbsp;
            <button
              class="btn btn-light btn-sm waves-effect waves-light"
              v-on:click="closeUpdateUser()"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import Multiselect from "vue-multiselect";
import CryptoJS from "crypto-js";
import { secretKey } from "../../../../api/global.env";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      projectCollaboratorsList: [
        {
          id: "1",
          name: "Select Collabrator",
        },
      ],
      projectList: [
        {
          id: "1",
          name: "Select Project",
        },
      ],
      username: "",
      email: "",
      password: "",
      userrole: [],
      githubEmailAddress: "",
      admin: [],
      userProfile: [],
      formData: {
        userRole: [],
        email: "",
        username: "",
        githubEmail: "",
        adminId: "",
        id: "",
        docManager: true,
        editor: false,
        publisher: false,
        docMigration: true,
      },
      error: null,
      submitted: false,
      allCollaborators: "",
      optionsCollaboratorlist: [
        {
          value: null,
          text: "Please select an collaborators",
          disabled: true,
        },
      ],
      selectedCollaborators: null,
      activeUserData: "",
      optionsProjectlist: [],
      seletedProject: null,
      optionsRoles: [],
      appAccess: "",
      DocMigrationOptions: [],
      optionsAdmins: [
        {
          value: null,
          text: "Please select an admin",
          disabled: true,
        },
      ],
      selectedAdmin: null,
      seletedRole: null,
      id: CryptoJS.AES.decrypt(this.$route.params.id, secretKey).toString(
        CryptoJS.enc.Utf8
      ),
    };
  },
  mounted() {
 
    this.getActiveUserData();

  },
  created() {
    if (!this.$store.state.Auth.gitToken) {
      this.$router.push({
        name: "Projects",
      });
    }
  },
  computed: {
    _() {
      return _;
    },
  },

  methods: {
    // This method is use to generate error or success message
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    getCollabrator() {
      this.$store.getters.client.get(``).then((response) => {
        this.projectCollaboratorsList = response.data;
      });
    },
    getUserProfile() {
      this.$store.getters.client
        .get(`orguser/details?userId=${this.id}`)
        .then((response) => {
          if (response.data) {
            this.formData = response.data;
            return Promise.resolve();
          } else {
            // Handle the case where response.data is empty or undefined
            this.messageToast("Error", "danger", "No user profile data found");
            return Promise.reject("No user profile data found");
          }
        })
        .then(async () => {
          let filterCollaborators = this._.filter(this.allCollaborators, {
            gitUsername: this.formData.githubUsername,
          });
          this.selectedCollaborators = filterCollaborators[0].gitUserId;
          this.getProjectBySeletedCollaborators(
            filterCollaborators[0].gitUserId
          );
          this.getOrgDetails();
          this.getOrgAdmins();
        })
        .catch((error) => {
          // Handle the error and display a message toast
          this.messageToast(
            "Error",
            "danger",
            error.response ? error.response.data.message : "An error occurred"
          );
        });
    },
    updateUser() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          let loader = this.$loading.show({
            loader: "dots",
          });
          let userIP = await fetch("https://api.ipify.org?format=json")
            .then((x) => x.json())
            .then(async ({ ip }) => {
              return ip;
            });
          let projectArr = [];
          this.seletedProject.forEach((element) => {
            projectArr.push(element.value);
          });
          let data = {
            projects: projectArr,
            userRole: this.formData.userRole,
            orgId: this.$store.state.Auth.orgId,
            email: this.formData.email,
            username: this.formData.username,

            githubCollaboratorId: this.selectedCollaborators,
            githubUsername: this.formData.githubUsername,
            githubEmail: this.formData.githubEmail,
            adminId: this.selectedAdmin,
            lastLoginId: userIP,
            isActive: true,
            isDeleted: false,
            isSuperUser: false,
            isProfileUpdate: true,
            isRoleAssign: true,
          };
          this.$store.getters.client
            .put(`orguser/details?userId=${this.id}`, data)
            .then(() => {
              this.messageToast(
                "Success",
                "success",
                `User profile update successfully`
              );
              this.$store.commit("setActiveUserCount", []);
              loader.hide();
              this.$router.push({
                path: `/users`,
              });
            })
            .catch((error) => {
              this.messageToast("Error", "danger", error.response.data.message);
              loader.hide();
            });
        }
      });
    },
    consolefunc() {},
    closeUpdateUser() {
      this.$router.push({
        path: `/users`,
      });
    },
    async getAllCollaboratorsData() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(
          `orgadmin/github/gitcollaborators?orgId=${this.$store.state.Auth.orgId}`
        )
        .then((response) => {
          if (response.data) {
            this.allCollaborators = response.data;
            this.allCollaborators.forEach((element) => {
              let filterCollaborators = this._.filter(this.activeUserData, {
                githubUsername: element.gitUsername,
              });
              if (filterCollaborators.length === 0) {
                this.optionsCollaboratorlist.push({
                  value: element.gitUserId,
                  text: element.gitUsername,
                });
              } else {
                this.optionsCollaboratorlist.push({
                  value: element.gitUserId,
                  text: element.gitUsername,
                  disabled: true,
                });
              }
            });
            this.getUserProfile();
            loader.hide();
          } else {
            // Handle the case where response.data is empty or undefined
            this.messageToast("Error", "danger", "No collaborator data found");
            loader.hide();
          }
        })
        .catch((error) => {
          // Handle the error and display a message toast
          this.messageToast(
            "Error",
            "danger",
            error.response ? error.response.data.message : "An error occurred"
          );
          loader.hide();
        });
    },
    getProjectBySeletedCollaborators(uid) {
      this.seletedProject = null;
      this.optionsProjectlist = [];
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`project/bygituserid?githubUserId=${uid}`)
        .then((response) => {
          if (response.data) {
            response.data.forEach((element) => {
              this.optionsProjectlist.push({
                value: element.id.toString(),
                text: element.name,
              });
            });
            return Promise.resolve();
          } else {
            // Handle the case where response.data is empty or undefined
            this.messageToast(
              "Error",
              "danger",
              "No project data found for the collaborator"
            );
            return Promise.reject("No project data found for the collaborator");
          }
        })
        .then(() => {
          let projectArr = [];
          this.formData.projects.forEach((element) => {
            let filterProjects = this._.filter(this.optionsProjectlist, {
              value: element,
            });
            projectArr.push(filterProjects[0]);
          });
          this.seletedProject = projectArr;
        })
        .catch((error) => {
          // Handle the error and display a message toast
          this.messageToast(
            "Error",
            "danger",
            error.response ? error.response.data.message : "An error occurred"
          );
        })
        .finally(() => {
          loader.hide();
        });
    },
    async getActiveUserData() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`orguser/active/profile?orgId=${this.$store.state.Auth.orgId}`)
        .then((response) => {
          if (response.data) {
            this.activeUserData = response.data;
            this.getAllCollaboratorsData();
          } else {
            // Handle the case where response.data is empty or undefined
            this.messageToast("Error", "danger", "No active user data found");
          }
        })
        .catch((error) => {
          // Handle the error and display a message toast
          this.messageToast(
            "Error",
            "danger",
            error.response ? error.response.data.message : "An error occurred"
          );
        })
        .finally(() => {
          loader.hide();
        });
    },
    // This method is use to Get organization details
    async getOrgDetails() {
      this.$store.getters.client
        .get(
          `/serveradmin/organization/byorgid?orgId=${this.$store.state.Auth.orgId}`
        )
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.appAccess = res.data[0];
            if (res.data[0].docManager)
              this.optionsRoles.push({
                text: "DocManager",
                value: "DocManager",
                disabled: true,
              });
            if (res.data[0].publisher)
              this.optionsRoles.push({
                text: "DocPublisher",
                value: "DocPublisher",
              });
            if (res.data[0].editor)
              this.optionsRoles.push({
                text: "DocEditor",
                value: "DocEditor",
              });
            if (res.data[0].docMigration)
              this.optionsRoles.push({
                text: "DocMigration",
                value: "DocMigration",
              });
            this.seletedRole = this.formData.userRole;
          } else {
            // Handle the case where res.data is empty or undefined
            this.messageToast(
              "Error",
              "danger",
              "No organization details found"
            );
          }
        })
        .catch((err) => {
          // Handle the error and display a message toast
          this.messageToast(
            "Error",
            "danger",
            err.response ? err.response.data.message : "An error occurred"
          );
        });
    },
    // This method is use to Get organization details
    async getOrgAdmins() {
      this.$store.getters.client
        .get(`orgadmin/byorgid?orgId=${this.$store.state.Auth.orgId}`)
        .then((res) => {
          if (res.data && res.data.length > 0) {
            res.data.forEach((element) => {
              this.optionsAdmins.push({
                value: element.id,
                text: element.name,
              });
            });
            this.selectedAdmin = this.formData.adminId;
          } else {
            this.messageToast(
              "Error",
              "danger",
              "No organization admins found"
            );
          }
        })
        .catch((err) => {
          this.messageToast("Error", "danger", err.response.data.error);
        });
    },
  },
};
</script>
<style scoped>
.disable-cust-cls {
  background: #e7e7e8 !important;
  color: #838588 !important;
}
.multiselect__tag {
  color: #515a6e !important;
  background-color: none;
}
</style>
