<template>
  <div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div
              class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <!-- Title and description -->
                        <h4 class="font-size-18 mt-4">Welcome !</h4>
                        <p class="text-muted">
                          Verify to continue to metR Client Admin.
                        </p>
                      </div>
                      <!-- Display alert if token has expired -->
                      <b-alert v-if="showtokenExpiry" show variant="danger">
                        Token expired! If you wish to obtain another link,
                        please contact the administrator using this email
                        address.
                        <a
                          href="mailto:techsupport@metr.com"
                          class="text-decoration-underline"
                          >techsupport@metr.com</a
                        >
                      </b-alert>
                      <!-- Display alert when verification is successful -->
                      <b-alert v-if="isVerify" show variant="success"
                        >Your account has been successfully verified, and
                        detailed instructions will be promptly sent to your
                        email address!</b-alert
                      >
                      <div class="mt-5 text-center">
                        <p>
                          <!-- Copyright notice -->
                          Copyright © {{ new Date().getFullYear() }}
                          <a href="https://metapercept.com/" target="_blank">
                            Metapercept Technology Services LLP
                          </a>
                          All Rights Reserved
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isVerify: false,
      showtokenExpiry: false,
    };
  },
  created() {
    this.tokenVerify();
  },
  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    async tokenVerify() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .put(`orgadmin/emailverification?token=${this.$route.params.token}`)
        .then(() => {
          this.isVerify = true;
          this.showtokenExpiry = false;
          loader.hide();
        })
        .catch(() => {
          this.isVerify = false;
          this.showtokenExpiry = true;
          loader.hide();
        });
    },
  },
};
</script>
<style></style>
