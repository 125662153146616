<template>
  <div>
    <!-- Card for Recent Notifications -->
    <div class="card">
      <div
        class="custom-notifications d-flex justify-content-between align-items-center"
      >
        <div class="custom-title text-wrap">Notifications</div>
        <div>
          <button
            class="btn text-primary view-details-btn text-nowrap btn-sm m-0"
            @click="viewMore"
          >
            <span class="mdi mdi-eye-outline mr-2"></span> View details
          </button>
        </div>
      </div>
      <div data-simplebar class="card-body border-top py-3">
        <ul class="list-unstyled activity-wid">
          <!-- Skeleton Loader While Data is Loading -->
          <b-skeleton-table
            v-if="showSpinner"
            :rows="5"
            :columns="2"
            :table-props="{ bordered: true, striped: true }"
          ></b-skeleton-table>
          <!-- Render Notifications if Data is Available and Loading is Complete -->
          <div v-else-if="activityData.length > 0 && !showSpinner">
            <li
              class="activity-list publication-list-cust-cls"
              v-for="(data, index) in activityData"
              :key="index"
            >
              <div class="d-flex justify-content-between">
                <div class="activity-icon avatar-xs">
                  <!-- Release Log Icon -->
                  <span
                    class="avatar-title bg-soft-primary text-primary rounded-circle font-size-24 avatar-title-cust-cls"
                  >
                    <i class="ri-github-line"></i>
                  </span>
                </div>
                <div>
                  <div>
                    <!-- Notification Title/Name -->
                    <h5 class="font-size-13 ptitle-cust-cls">
                      {{ data.publication_name }}
                    </h5>
                  </div>
                  <div>
                    <!-- Notification Message -->
                    <p class="text-muted mb-0">{{ data.message }}</p>
                  </div>
                </div>
                <div>
                  <!-- Notification Timestamp -->
                  <p class="text-muted mb-0 smaller-text">
                    {{ getTimeAgo(data.releaseAt) }}
                  </p>
                </div>
              </div>
            </li>
          </div>
          <!-- Placeholder for No Notifications Available -->
          <li v-else>
            <div
              class="d-flex justify-content-center align-items-center m-0 p-0"
            >
              <div class="placeholder-content">
                <img
                  src="../../../assets/placeholder.png"
                  class="placeholder-image"
                />
                <div class="placeholder-text">
                  There have been no recent notifications in the last 24 hours.
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { axios } from "@/api/index.js";
import { handleApiError } from "../../../apiErrorHandler.js";
import { URL } from "@/api/global.env.js";
export default {
  data() {
    return {
      activityData: [],
      showSpinner: true,
      orgId: this.$store.state.Auth.orgId,
    };
  },
  mounted() {
    if (this.$store.state.Auth.recentNotification.length) {
      this.showSpinner = false;
      const recentNotification = [];
      this.$store.state.Auth.recentNotification.forEach((ele) => {
        this.showSpinner = false;
        recentNotification.push({
          publication_name: ele.projectName,
          icon: "ri-price-tag-3-fill",
          message: ele.message,
          author: ele.releasedBy,
          releaseAt: ele.commiter.date,
        });
      });
      this.activityData = recentNotification.slice(0, 5);
    } else {
      this.getNotification();
    }
  },
  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    viewMore() {
      this.$router.push({
        name: "RecentNotificationstable",
      });
    },
    getTimeAgo(timestamp) {
      const now = new Date();
      const timeDiff = now.getTime() - new Date(timestamp).getTime();
      const minuteDiff = Math.floor(timeDiff / 60000); // 60000 milliseconds in a minute
      if (minuteDiff < 1) {
        return "just now";
      } else if (minuteDiff < 60) {
        return `${minuteDiff} minute${minuteDiff > 1 ? "s" : ""} ago`;
      } else {
        const hourDiff = Math.floor(minuteDiff / 60);
        if (hourDiff < 24) {
          return `${hourDiff} hour${hourDiff > 1 ? "s" : ""} ago`;
        } else {
          const dayDiff = Math.floor(hourDiff / 24);
          if (dayDiff < 30) {
            return `${dayDiff} day${dayDiff > 1 ? "s" : ""} ago`;
          } else {
            const monthDiff = Math.floor(dayDiff / 30);
            if (monthDiff < 12) {
              return `${monthDiff} month${monthDiff > 1 ? "s" : ""} ago`;
            } else {
              const yearDiff = Math.floor(monthDiff / 12);
              return `${yearDiff} year${yearDiff > 1 ? "s" : ""} ago`;
            }
          }
        }
      }
    },
    getNotification() {
      if (this.$store.state.Auth.gitToken) {
        const gitToken = this.$store.state.Auth.gitToken;
        const orgId = this.$store.state.Auth.orgId;
        const token = this.$store.state.Auth.token;
        const userId = this.$store.state.Auth.userId;
        axios({
          method: "get",
          url: `${URL}api/orgadmin/notification/24hrsCommit?orgId=${orgId}`,
          headers: {
            gitToken,
            Authorization: `Bearer ${token}`,
            userId,
            orgId,
          },
        })
          .then((res) => {
            this.showSpinner = false;
            const recentNotification = [];
            res.data.forEach((ele) => {
              this.showSpinner = false;
              recentNotification.push({
                publication_name: ele.projectName,
                icon: "ri-price-tag-3-fill",
                message: ele.message,
                author: ele.releasedBy,
                releaseAt: ele.commiter.date,
              });
            });
            this.activityData = recentNotification;
            this.$store.commit("setrecentNotification", res.data);
          })
          .catch((error) => {
            this.showSpinner = false;
            handleApiError(error);
          });
      } else {
        this.$router.push("/github");
      }
    },
  },
};
</script>
<style scoped>
.custom-notifications {
  width: Fill (614px);
  height: Hug (60px);
  padding: 14px;
  border: 0px, 0px, 0.3px, 0px;
  gap: 24px;
}
.custom-title {
  width: Fill (566px);
  height: Hug (12px);
  gap: 10px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-align: left;
  color: rgba(23, 35, 61, 1);
}
.view-details-btn {
  background-color: rgba(113, 165, 203, 0.2);
  word-wrap: normal;
}
.ptitle-cust-cls {
  margin-bottom: 0;
}
.publication-card-body-cust-cls {
  padding: 0.25rem 1.25rem !important;
}
.publication-list-cust-cls {
  padding: 0 0 25px 30px !important;
}
.scrollable-content {
  height: inherit;
}
.smaller-text {
  font-size: smaller;
}
.placeholder-content {
  text-align: center;
}
.placeholder-image {
  height: 200px;
}
.placeholder-text {
  color: #666;
  font-size: 16px;
  text-align: center;
}
.card {
  height: 44.5rem;
}
@media (max-width: 1199px) {
  .card {
    max-height: 500px;
  }
}
</style>
