<template>
  <div>
    <!-- Row for Pagination and Search -->
    <div class="row">
      <div class="col-12">
        <div>
          <div class="card-body">
            <div class="row">
              <!-- Per Page Dropdown for Entries -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-flex align-items-center"
                    ><span class="text-nowrap">Show entries</span>
                    <div class="col-md-3">
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                      ></b-form-select>
                    </div>
                  </label>
                </div>
              </div>
              <!-- Search Input Field -->
              <div class="col-md-6 mb-2 d-flex justify-content-end">
                <div id="tickets-table_filter" class="dataTables_filter">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search Username..."
                    class="form-control form-control-sm"
                  ></b-form-input>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <!-- Buefy Table Component -->
              <b-table
                :items="activeUserData"
                :fields="colfields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :filter-function="filterTable"
                ref="activeUserTable"
                show-empty
              >
                <!-- Custom cell rendering for 'status' column -->
                <template #cell(status)="row">
                  <div
                    class="badge font-size-12"
                    :class="{
                      'badge-soft-danger': `${row.item.isActive}` === 'false',
                      'badge-soft-success': `${row.item.isActive}` === 'true',
                    }"
                  >
                    {{ row.item.isActive === true ? "Active" : "Deactive" }}
                  </div>
                </template>
                <!-- Custom cell rendering for 'action' column -->
                <template #cell(action)="row">
                  <b-dropdown
                    right
                    toggle-class="arrow-none card-drop"
                    class="float-right"
                    variant="white"
                  >
                    <template v-slot:button-content>
                      <i class="mdi mdi-dots-vertical"></i>
                    </template>
                    <!-- Dropdown menu items -->
                    <b-dropdown-item
                      @click="userUpdate(row)"
                      :ref="'btnActiveEdit' + row.index"
                      >Edit</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-if="row.item.isActive === false"
                      @click="active(row)"
                      :ref="'btnActiveIna' + row.index"
                      >Activate</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-else
                      @click="deactive(row)"
                      :ref="'btnActiveIna' + row.index"
                      >Deactivate</b-dropdown-item
                    >
                    <b-dropdown-item @click="openChangePasswordModel(row)"
                      >Change Password</b-dropdown-item
                    >
                  </b-dropdown>
                </template>
                <!-- Custom cell rendering for 'role' column -->
                <template #cell(role)="row">
                  {{ row.item.userRole.join(", ") }}
                </template>
              </b-table>
            </div>
            <!-- Pagination and Row Count -->
            <div class="row">
              <!-- Row Count -->
              <div
                class="dataTables_paginate paging_simple_numbers col justify-content-center row-count-cust-cls"
              ></div>
              <!-- Pagination -->
              <div class="row-pagination-cust-cls">
                <ul class="pagination-rounded mb-0">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Change Password Modal -->
    <div>
      <div class="my-4 text-center">
        <b-modal
          id="modal-standard"
          ref="activeuserChangePasswordModal"
          title="Change Password"
          title-class="font-18"
          hide-footer
        >
          <div class="row">
            <div class="col-lg-12">
              <form
                class="form-horizontal"
                @submit.prevent="activeuserchangePassword"
                ref="formActiveuserChangePasswords"
              >
                <!-- New Password Input Field -->
                <div class="form-group">
                  <label for="a_user_password">New Password</label>
                  <input
                    v-validate="'required|verify_password'"
                    v-model="a_user_password"
                    name="a_user_password"
                    type="password"
                    class="form-control"
                    id="a_user_password"
                    placeholder="Enter new password"
                    :class="{
                      'is-invalid': submitted && $v.a_user_password.$error,
                    }"
                    ref="a_user_password"
                  />
                  <!-- Validation messages for 'a_user_password' -->
                  <p
                    class="text-danger text-sm"
                    v-show="
                      errors.has('a_user_password') &&
                      _.find(errors.items, { field: 'a_user_password' }).rule ==
                        'required'
                    "
                  >
                    <span>The password field is required</span>
                  </p>
                  <p
                    class="text-danger text-sm"
                    v-show="
                      errors.has('a_user_password') &&
                      _.find(errors.items, { field: 'a_user_password' }).rule ==
                        'verify_password'
                    "
                  >
                    {{ errors.first("a_user_password") }}
                  </p>
                </div>
                <!-- Confirm Password Input Field -->
                <div class="form-group">
                  <label for="a_user_password_confirmation"
                    >Confirm Password</label
                  >
                  <input
                    v-validate="'required|confirmed:a_user_password'"
                    v-model="a_user_password_confirmation"
                    name="a_user_password_confirmation"
                    type="password"
                    class="form-control"
                    id="a_user_password_confirmation"
                    placeholder="Enter password"
                    :class="{
                      'is-invalid':
                        submitted && $v.a_user_password_confirmation.$error,
                    }"
                  />
                  <!-- Validation message for password confirmation -->
                  <p
                    class="text-danger text-sm"
                    v-show="errors.has('a_user_password_confirmation')"
                  >
                    The password confirmation field does not match
                  </p>
                </div>
                <div class="text-center">
                  <!-- Save and Cancel Buttons for Password Change -->
                  <button
                    class="btn btn-primary w-md waves-effect waves-light"
                    type="submit"
                  >
                    Save
                  </button>
                  &nbsp;&nbsp;
                  <b-button
                    class="btn btn-secondry w-md waves-effect waves-light"
                    v-on:click="closeChangePasswordModel()"
                    >Cancel</b-button
                  >
                </div>
              </form>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import VeeValidate from "vee-validate";
import Swal from "sweetalert2";
import { handleApiError } from "../../../../apiErrorHandler.js";
import _ from "lodash";
import CryptoJS from "crypto-js";
import { secretKey } from "../../../../api/global.env";
export default {
  data() {
    return {
      activeUserData: [],
      currentUserToChangePassword: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: true,
      colfields: [
        {
          key: "username",
          label: "Username",
          sortable: false,
        },
        {
          key: "role",
          label: "Role",
          sortable: false,
        },
        {
          key: "adminName",
          label: "Admin",
          sortable: false,
        },
        {
          key: "status",
          label: "Status",
          sortable: false,
        },
        {
          key: "action",
          label: "",
          sortable: false,
        },
      ],
      a_user_password: null,
      a_user_password_confirmation: null,
      error: null,
      submitted: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.activeUserData.length;
    },
    _() {
      return _;
    },
  },
  created() {
    if (this.$store.state.Auth.activeUserData.length) {
      this.activeUserData = this.$store.state.Auth.activeUserData;
      this.totalRows = this.activeUserData.length;
    } else {
      this.getActiveUserData();
    }
    VeeValidate.Validator.extend("verify_password", {
      getMessage: () =>
        `The password must contain at least: minimum 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)`,
      validate: (value) => {
        var strongRegex = new RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})"
        );
        return strongRegex.test(value);
      },
    });
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    // This method is use to generate error or success message
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    getActiveUserData() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`orguser/active/profile?orgId=${this.$store.state.Auth.orgId}`)
        .then((response) => {
          if (response.data) {
            // Data is available, update the component's property
            this.activeUserData = response.data;
            this.totalRows = this.activeUserData.length;

            this.$store.commit("setActiveUserCount", this.activeUserData);
            loader.hide();
          } else {
            // Handle the case where response.data is empty or undefined
            this.messageToast("Error", "danger", "No active user data found");
            loader.hide();
          }
        })
        .catch((err) => {
          loader.hide();
          handleApiError(err);
          this.messageToast(
            "Error",
            "danger",
            err.response ? err.response.data.message : "An error occurred"
          );
        });
    },
    openChangePasswordModel(data) {
      this.currentUserToChangePassword = data.item.id;
      this.$refs["activeuserChangePasswordModal"].show();
    },
    closeChangePasswordModel() {
      // Resetting Values
      this.a_user_password = this.a_user_password_confirmation = "";
      this.$refs.formActiveuserChangePasswords.reset();
      this.$refs["activeuserChangePasswordModal"].hide();
    },
    activeuserchangePassword() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let loader = this.$loading.show({
            loader: "dots",
          });
          //   state.Auth.activeUserData.id
          this.$store.getters.client
            .put(`/orguser/changepassword/byuserid`, {
              userId: this.currentUserToChangePassword,
              password: this.a_user_password,
            })
            .then(() => {
              // Resetting Values
              this.a_user_password = this.a_user_password_confirmation = "";
              this.$refs.formActiveuserChangePasswords.reset();
              this.messageToast(
                "Success",
                "success",
                `Password reset successfully`
              );
              loader.hide();
              this.$refs["activeuserChangePasswordModal"].hide();
            })
            .catch((error) => {
              handleApiError(error);
              this.messageToast("Error", "danger", error.response.data.message);
              loader.hide();
            });
        }
      });
    },
    userUpdate(data) {
      const encryptedData = CryptoJS.AES.encrypt(
        data.item.id,
        secretKey
      ).toString();
      const encodeddata = encodeURIComponent(encryptedData);
      this.$router.push({
        path: `/userupdate/${encodeddata}`,
      });
    },
    deactive(data) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to deactivate this user!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          let loader = this.$loading.show({
            loader: "dots",
          });
          this.$store.getters.client
            .put(`orguser/activeinactive/profile`, {
              userId: data.item.id,
              isActive: false,
            })
            .then(() => {
              this.getActiveUserData();
              this.$refs.activeUserTable.refresh();
              this.$refs["activeUserTable"].refresh();
              loader.hide();
              Swal.fire("Deactivate!", "User has been deactivated.", "success");
            })
            .catch(() => {
              loader.hide();
            });
        }
      });
    },
    active(data) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to activate this user!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          let loader = this.$loading.show({
            loader: "dots",
          });
          this.$store.getters.client
            .put(`orguser/activeinactive/profile`, {
              userId: data.item.id,
              isActive: true,
            })
            .then(() => {
              this.getActiveUserData();
              this.$refs.activeUserTable.refresh();
              this.$refs["activeUserTable"].refresh();
              loader.hide();
              Swal.fire("Active!", "User has been activated.", "success");
            })
            .catch(() => {
              loader.hide();
            });
        }
      });
    },
    filterTable(row, filter) {
      const filterUpperCase = filter.toUpperCase();
      const hasNameMatch = row.username.toUpperCase().includes(filterUpperCase);
      return hasNameMatch;
    },
  },
};
</script>
<style>
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .row-count-cust-cls {
    margin-bottom: 10px;
  }
  .row-pagination-cust-cls {
    margin-top: 20px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .row-count-cust-cls ul {
    margin-bottom: 10px;
    text-align: left !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
