import Vue from "vue";
import Vuex from "vuex";
import { axios } from "@/api/index.js";
import modules from "./modules";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html

  state: {
    sgitrepodata: [],
  },
  mutations: {
    setGitRepoDetails: (state, e) => (state.sgitrepodata = e.data),
    removeRepoData(state) {
      state.sgitrepodata = [];
    },
  },
  actions: {
    async githubApiRequest({ commit, state }, payload) {
      try {
        commit;
        state;
        let gitToken = this.$store.state.Auth.gitToken;
        let apptoken = this.$store.state.Auth.token;
        const { data } = await axios.get(
          `${process.env.VUE_APP_DATABASEURL}/${payload}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apptoken}`,
              gitToken: `${gitToken}`,
            },
          }
        );
        return data;
      } catch (error) {
        error;
      }
    },
  },

  strict: process.env.NODE_ENV !== "production",
});

export default store;
