<template>
<div>
    <!-- Page Header Component -->
    <PageHeader :icon="'mdi mdi-account-circle h3'" :title="title" :items="items" />
    <!-- Details Component -->
    <Details />
</div>
</template>
<script>
import PageHeader from '../../../components/pageheader.vue';
import Details from './components/details.vue';
import {
    eventBus
} from '../../../main';
export default {
    components: {
        PageHeader,
        Details
    },
    data() {
        return {
            title: "Profile",
            items: [{
                    text: "Dashboard",
                    href: "/"
                },
                {
                    text: "Profile",
                    active: true
                }
            ],
        };
    },
    mounted() {
        eventBus.$emit('update-sidebar', "menuitems.profile.text");
    }
};
</script>
