<template>
    <div>
        <!-- PageHeader component with icon, title, and items as props -->
        <PageHeader :icon="'mdi mdi-account-multiple h3'" :title="title" :items="items" />
        <!-- AddUser component -->
        <AddUser />
    </div>
</template>
<script>
import PageHeader from "../../../components/pageheader.vue";
import AddUser from "./components/addUser.vue";
import {
    eventBus
} from "../../../main";
export default {
    components: {
        PageHeader,
        AddUser,
    },
    data() {
        return {
            title: "Add User",
            items: [{
                text: "Dashboard",
                href: "/"
            },
            {
                text: "Users",
                href: "/users"
            },
            {
                text: "Add User",
                active: true
            }
            ],
        };
    },
    created() {
        if (!this.$store.state.Auth.gitToken) {
            this.$router.push({
                name: "Projects"
            });
        }
    },
    mounted() {
        eventBus.$emit('update-sidebar', "menuitems.users.text");
    }
};
</script>
