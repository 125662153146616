var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-container',[_c('b-card',[_c('form',{ref:"formAddUser",staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();return _vm.addUser.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"addf_username"}},[_vm._v("Username "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.formData.username),expression:"formData.username"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.addf_username.$error,
                },attrs:{"name":"addf_username","type":"text","id":"addf_username","placeholder":"Enter username"},domProps:{"value":(_vm.formData.username)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "username", $event.target.value)}}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('addf_username')),expression:"errors.has('addf_username')"}],staticClass:"text-danger text-sm"},[_vm._v(" The username field is required ")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"addf_collaborators"}},[_vm._v("Collaborators "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.addf_collaborators.$error,
                },attrs:{"options":_vm.optionsCollaboratorlist,"name":"addf_collaborators","type":"text","id":"addf_collaborators","placeholder":"Select collaborators"},on:{"change":_vm.onCollaboratorSel},model:{value:(_vm.selectedCollaborators),callback:function ($$v) {_vm.selectedCollaborators=$$v},expression:"selectedCollaborators"}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('addf_collaborators')),expression:"errors.has('addf_collaborators')"}],staticClass:"text-danger text-sm"},[_vm._v(" The Collaborators field is required ")])],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"addf_projects"}},[_vm._v("Projects "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('multiselect',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
                  'is-invalid': _vm.submitted && _vm.$v.addf_projects.$error,
                },attrs:{"name":"addf_projects","id":"addf_projects","tag-placeholder":"Add this as a new tag","placeholder":"Please select a project","label":"text","track-by":"value","options":_vm.optionsProjectlist,"multiple":true},model:{value:(_vm.seletedProject),callback:function ($$v) {_vm.seletedProject=$$v},expression:"seletedProject"}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('addf_projects')),expression:"errors.has('addf_projects')"}],staticClass:"text-danger text-sm"},[_vm._v(" The projects field is required ")])],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"addf_email"}},[_vm._v("Email Address "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.formData.email),expression:"formData.email"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.addf_email.$error,
                },attrs:{"name":"addf_email","type":"text","id":"addf_email","placeholder":"Enter email"},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "email", $event.target.value)}}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('addf_email')),expression:"errors.has('addf_email')"}],staticClass:"text-danger text-sm"},[_vm._v(" The email field is required ")])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"addf_password"}},[_vm._v("Password "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"input-group"},[((_vm.showPasswordCfrm ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required|verify_password'),expression:"'required|verify_password'"},{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],ref:"addf_password",staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.addf_password.$error,
                  },attrs:{"name":"addf_password","id":"addf_password","placeholder":"Enter password","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.password)?_vm._i(_vm.password,null)>-1:(_vm.password)},on:{"change":function($event){var $$a=_vm.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.password=$$a.concat([$$v]))}else{$$i>-1&&(_vm.password=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.password=$$c}}}}):((_vm.showPasswordCfrm ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required|verify_password'),expression:"'required|verify_password'"},{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],ref:"addf_password",staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.addf_password.$error,
                  },attrs:{"name":"addf_password","id":"addf_password","placeholder":"Enter password","type":"radio"},domProps:{"checked":_vm._q(_vm.password,null)},on:{"change":function($event){_vm.password=null}}}):_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required|verify_password'),expression:"'required|verify_password'"},{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],ref:"addf_password",staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.addf_password.$error,
                  },attrs:{"name":"addf_password","id":"addf_password","placeholder":"Enter password","type":_vm.showPasswordCfrm ? 'text' : 'password'},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('div',{staticClass:"btn btn-outline-light",attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleCfrmPasswordVisibility()}}},[_c('i',{class:_vm.eyeIconClassCfrm})])])]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.errors.has('addf_password') &&
                  _vm._.find(_vm.errors.items, { field: 'addf_password' }).rule ==
                    'required'
                ),expression:"\n                  errors.has('addf_password') &&\n                  _.find(errors.items, { field: 'addf_password' }).rule ==\n                    'required'\n                "}],staticClass:"text-danger text-sm"},[_c('span',[_vm._v("The password field is required")])]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.errors.has('addf_password') &&
                  _vm._.find(_vm.errors.items, { field: 'addf_password' }).rule ==
                    'verify_password'
                ),expression:"\n                  errors.has('addf_password') &&\n                  _.find(errors.items, { field: 'addf_password' }).rule ==\n                    'verify_password'\n                "}],staticClass:"text-danger text-sm"},[_vm._v(" "+_vm._s(_vm.errors.first("addf_password"))+" ")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"addf_githubUsername"}},[_vm._v("Github Username "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.formData.githubUsername),expression:"formData.githubUsername"}],staticClass:"form-control disable-cust-cls",class:{
                  'is-invalid': _vm.submitted && _vm.$v.addf_githubUsername.$error,
                },attrs:{"name":"addf_githubUsername","type":"text","id":"addf_githubUsername","placeholder":"Enter Github Username","disabled":""},domProps:{"value":(_vm.formData.githubUsername)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "githubUsername", $event.target.value)}}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('addf_githubUsername')),expression:"errors.has('addf_githubUsername')"}],staticClass:"text-danger text-sm"},[_vm._v(" The GitHub username field is required ")])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"addf_githubEmail"}},[_vm._v("Github Email Address "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.formData.githubEmail),expression:"formData.githubEmail"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.addf_githubEmail.$error,
                },attrs:{"name":"addf_githubEmail","type":"text","id":"addf_githubEmail","placeholder":"Enter GitHub email address"},domProps:{"value":(_vm.formData.githubEmail)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "githubEmail", $event.target.value)}}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('addf_githubEmail')),expression:"errors.has('addf_githubEmail')"}],staticClass:"text-danger text-sm"},[_vm._v(" The GitHub email field is required ")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"addf_admin"}},[_vm._v("Admin "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.addf_admin.$error,
                },attrs:{"options":_vm.optionsAdmins,"name":"addf_admin","value-field":"value","text-field":"text","id":"addf_admin","placeholder":"Select admin"},model:{value:(_vm.selectedAdmin),callback:function ($$v) {_vm.selectedAdmin=$$v},expression:"selectedAdmin"}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('addf_admin')),expression:"errors.has('addf_admin')"}],staticClass:"text-danger text-sm"},[_vm._v(" The projects field is required ")])],1)])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"addf_userrole"}},[_vm._v("User Role "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-checkbox-group',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
              'is-invalid': _vm.submitted && _vm.$v.addf_userrole.$error,
            },attrs:{"options":_vm.optionsRoles,"name":"add_userrole","type":"text","value":"true","id":"addf_userrole","multiple":true},model:{value:(_vm.formData.userRole),callback:function ($$v) {_vm.$set(_vm.formData, "userRole", $$v)},expression:"formData.userRole"}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('addf_userrole')),expression:"errors.has('addf_userrole')"}],staticClass:"text-danger text-sm"},[_vm._v(" The user roles field is required ")])],1),_c('hr'),_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-sm btn-primary waves-effect waves-light",attrs:{"type":"submit"}},[_vm._v(" Save ")]),_vm._v("    "),_c('button',{staticClass:"btn btn-default btn-sm",on:{"click":function($event){return _vm.closeAddUser()}}},[_vm._v(" Cancel ")])])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }